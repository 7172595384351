import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Switch } from 'antd';
import { faPenToSquare, faCircleInfo, faEdit } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useContextHook } from '../../contexts/GeneralContext';
import SimpleAccordion from '../Accordion/SimpleAccordion';
import PurchaseTable from '../Table/PurchaseTable';
import PaymentExtraValuesForm from './PaymentExtraValuesForm';
import { Div, colors, spaces } from '../../styles/style';
import { Paragraph, Subtitle } from '../Text/Text';
import TooltipIcon from '../Tooltip/TooltipIcon';
import { Content } from '../../_Pages/Payments/Payment.style';
import Button from '../Button/Button';

import CompleteApportionmentModal from '../Modal/CompleteApportionmentModal';
import AddRefurbishApportionmentModal from '../Modal/AddRefurbishApportionmentModal';
import SimpleTable from '../Table/SimpleTable';
import { TableContainer } from '../Table/CashFlowTable.style';
import { apportionmentTotalsColumns } from '../../lib/mapping/TableOrList/apportionmentColumns';
import useCRUD from '../../_Hooks/useCRUD';
import { clearItemToApportionment, mountApportionmentItems } from '../../lib/helpers/payment';
import useViewport from '../../_Hooks/useViewport';

const PaymentFormItemContent = ({
  list,
  idRefurbish,
  onchangeInput,
  itemObjectsMap,
  itemSettersMap,
  isView,
  fullTable,
  isCreatePayment,
  priceWithBdi,
  shouldForceRender = true,
  importedFromXML,
  invalidPaymentItems,
  setInvalidPaymentItems,
  isApportionmentList,
  setApportionmentDrawer
}) => {
  const { isMobile } = useViewport(window.innerWidth);
  const _isMobile = isMobile();

  const [isLoading, setIsLoading] = useState(false);
  const [forceRender, setForceRender] = useState(shouldForceRender);

  const handleSetList = setFunc => {
    const result = setFunc(list);
    onchangeInput(result);
  };

  const handleForceRender = () => {
    if (!list.length) {
      setForceRender(false);
      return;
    }

    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      setForceRender(false);
    }, 100);
  };

  useEffect(() => {
    if (importedFromXML) handleForceRender();
  }, [fullTable]);

  useEffect(() => {
    if (forceRender && list) handleForceRender();
  }, [list]);

  return (
    <Div direction="column" $fullWidth align="start">
      {isLoading ? (
        <Div height={isView ? '100%' : '300px'} />
      ) : (
        <Div $fullWidth display="block" className="customScale">
          {isApportionmentList ? (
            <>
              <TableContainer padding={`0  0 ${spaces.space1} 0`}>
                <SimpleTable
                  columns={apportionmentTotalsColumns({ isMobile: _isMobile })}
                  data={list || []}
                  isExpandable={false}
                  $borderBottom
                  border={_isMobile ? 'none' : undefined}
                />
              </TableContainer>
              <Button
                type="primary"
                text
                onClick={e => {
                  e.stopPropagation();
                  setApportionmentDrawer({ open: true, type: 'complete', hasEmptyLists: false });
                }}
              >
                <FontAwesomeIcon icon={faPenToSquare} />
                Editar itens
              </Button>
            </>
          ) : (
            <PurchaseTable
              list={list || []}
              setList={handleSetList}
              idRefurbish={idRefurbish}
              itemObjectsMap={itemObjectsMap}
              itemSettersMap={itemSettersMap}
              paymentView
              isCreatePayment={isCreatePayment}
              childrenColumnName={fullTable ? 'paymentItemLevels' : ''}
              parentColumnName="paymentItems"
              readOnly={isView}
              height={isView ? '100%' : '300px'}
              limitedTable={!fullTable}
              priceWithBdi={priceWithBdi}
              invalidPaymentItems={invalidPaymentItems}
              setInvalidPaymentItems={setInvalidPaymentItems}
              updateFilters={false}
            />
          )}
        </Div>
      )}
      <Div $fullWidth justify="end">
        <PaymentExtraValuesForm creditDisabled={isView} showOtherTaxes />
      </Div>
    </Div>
  );
};

PaymentFormItemContent.propTypes = {
  list: PropTypes.instanceOf(Array),
  isView: PropTypes.bool,
  idRefurbish: PropTypes.number,
  onchangeInput: PropTypes.func,
  itemObjectsMap: PropTypes.instanceOf(Object),
  itemSettersMap: PropTypes.instanceOf(Object),
  fullTable: PropTypes.bool,
  isCreatePayment: PropTypes.bool,
  priceWithBdi: PropTypes.bool,
  importedFromXML: PropTypes.bool,
  shouldForceRender: PropTypes.bool,
  invalidPaymentItems: PropTypes.bool,
  setInvalidPaymentItems: PropTypes.func,
  isApportionmentList: PropTypes.bool,
  setApportionmentDrawer: PropTypes.func
};

const PaymentFormItem = () => {
  const {
    values,
    setField,
    isView,
    itemObjectsMap,
    itemSettersMap,
    isCreate,
    loadingItems,
    invalidPaymentItems,
    setInvalidPaymentItems,
    apportionmentDrawer,
    setApportionmentDrawer,
    clearStates
  } = useContextHook();
  const { vobiPayPaymentType, paymentStatuses, installmentStatuses } = useSelector(state => state.setup.enums);
  const [openCompleteApportionment, setOpenCompleteApportionment] = useState(false);

  const { handleCreate: handleCreateLibraryItem, loading: loadingLibraryItems } = useCRUD({
    model: 'refurbish-items',
    immediatelyLoadData: false
  });

  const {
    paymentItems,
    idRefurbish,
    isVobiPay,
    paymentTypes,
    total,
    paymentStatus,
    isCharge,
    id,
    ownBusiness,
    priceWithBdi,
    idOrder,
    importedFromXML,
    paymentComplete,
    completeApportionmentList,
    refurbishesList,
    installments,
    isOrderPayment
  } = values || {};

  const [refurbishesSplit, setRefurbishesSplit] = useState(refurbishesList || []);

  const creditDisabled = isVobiPay && paymentTypes?.some(type => type?.id === vobiPayPaymentType?.creditCard);
  const hasPaidInstallment = installments?.some(i => installmentStatuses?.allPaid?.includes(i?.idInstallmentStatus));
  const disableSplit = hasPaidInstallment;
  const disableSplitText = idOrder
    ? 'Não é possível ratear a despesa a partir da ordem de compra.'
    : 'Não é possível ratear essa despesa pois ela já possui uma parcela paga.';

  const onchangeInput = newList => {
    setField('paymentItems')(newList);
  };

  useEffect(() => {
    if (!isView && !isCharge && !id) setField('value')(total, true);
  }, [total]);

  const RenderTable = (
    <PaymentFormItemContent
      isView={isView || creditDisabled || paymentStatus?.id === paymentStatuses.paid}
      invalidPaymentItems={invalidPaymentItems}
      setInvalidPaymentItems={setInvalidPaymentItems}
      list={!loadingItems ? paymentItems || [] : null}
      onchangeInput={onchangeInput}
      idRefurbish={idRefurbish}
      itemSettersMap={itemSettersMap}
      itemObjectsMap={itemObjectsMap}
      fullTable={!ownBusiness || values?.billType === 'expense'}
      isCreatePayment={isCreate}
      priceWithBdi={priceWithBdi}
      shouldForceRender
      importedFromXML={importedFromXML}
      isApportionmentList={values?.billType === 'expense' && paymentComplete && completeApportionmentList}
      setApportionmentDrawer={setApportionmentDrawer}
    />
  );

  const handleApportionment = (_apportionmentList, newPaymentItems, totals, newRefurbishes) => {
    setApportionmentDrawer({ open: false });
    const apportionmentLength = Object.keys(_apportionmentList).length;
    const allApportionmentIsEmpty = Object.values(_apportionmentList).every(arr => arr?.length === 0);
    const isEmpty = apportionmentLength === 0 || allApportionmentIsEmpty;
    if (isEmpty || apportionmentLength === 1) {
      if (isEmpty) {
        setField('paymentItems')([]);
        setField('value')(0);
      } else {
        setField('paymentItems')(newPaymentItems);
        setField('idRefurbish')(newRefurbishes[0]?.id || newRefurbishes[0]?.value || null);
        setField('value')(totals?.[1] || 0);
      }
      setField('completeApportionmentList')(null);
      setField('isApportionment')(false);
      setRefurbishesSplit([]);
      setField('completeApportionmentTotals')(null);
      return;
    }
    setField('paymentItems')(newPaymentItems);
    setField('completeApportionmentList')(_apportionmentList);
    setField('isApportionment')(true);
    setField('completeApportionmentTotals')(totals);
    setRefurbishesSplit(newRefurbishes);
  };

  const handleSwitchPriceBdi = val => {
    setField('priceWithBdi')(val);

    const newPaymentItems = paymentItems?.map(data => {
      if (val) {
        return { ...data, price: data.priceBdi || data?.price };
      }
      return { ...data, price: data.priceUnit || data?.price };
    });
    setField('paymentItems')(newPaymentItems);
  };

  const SwitchPriceBdi =
    isCharge && !id && !idOrder ? (
      <Div gap={spaces.space1}>
        <Switch
          id="switchPriceBdi"
          checked={priceWithBdi}
          onChange={(checked, e) => {
            e.stopPropagation();
            handleSwitchPriceBdi(checked);
          }}
        />
        <Paragraph type="small">{priceWithBdi ? 'Preço (com BDI)' : 'Custo (sem BDI)'}</Paragraph>
        <TooltipIcon
          text="Alterne entre as opções de preço e custo para lançamentos com ou sem o BDI no valor de cada item."
          icon={faCircleInfo}
          iconColor={colors.primary500}
        />
      </Div>
    ) : (
      <>
        {!completeApportionmentList && !isCharge ? (
          <Button
            type="primary"
            ghost
            disabled={disableSplit}
            tooltipText={disableSplit ? disableSplitText : null}
            onClick={e => {
              e.stopPropagation();
              setOpenCompleteApportionment(true);
            }}
          >
            <FontAwesomeIcon icon={faEdit} />
            Rateio por projeto
          </Button>
        ) : null}
      </>
    );

  const confirmCompleteApportionment = refurbishList => {
    const listToCreate = paymentItems.filter(item => !item.idItem);
    const func = listToCreate?.length
      ? handleCreateLibraryItem({ values: { list: listToCreate }, refresh: false, postPathOptions: '/copyBulk' })
      : Promise.resolve([]);
    func.then(libraryData => {
      const newList = mountApportionmentItems({
        refurbishList,
        paymentItemsList: paymentItems,
        libraryData
      });
      const assemblyItems = clearItemToApportionment(newList, idRefurbish);
      setField('completeApportionmentList')(assemblyItems);
      setRefurbishesSplit(refurbishList);
      setOpenCompleteApportionment(false);
      setApportionmentDrawer({ open: true, type: 'complete', isCreate: true });
      setOpenCompleteApportionment(false);
    });
  };

  const cancelCompleteApportionment = () => {
    if (Object.values(completeApportionmentList)?.every(arr => arr?.length === 0) || apportionmentDrawer?.isCreate) {
      setField('completeApportionmentList')(null);
      setRefurbishesSplit([]);
      setOpenCompleteApportionment(false);
    }
    setApportionmentDrawer({ open: false });
  };

  const objectRefurbishes = refurbishesSplit.reduce((acc, item) => {
    acc[item.id || item?.value] = item;
    return acc;
  }, {});

  return (
    <>
      {isView ? (
        <Content>
          <Subtitle>Produtos/Serviços</Subtitle>
          {RenderTable}
        </Content>
      ) : (
        <SimpleAccordion title="Produtos/Serviços" initOpen withPaddingContainer extraHeaderComponent={SwitchPriceBdi}>
          {RenderTable}
        </SimpleAccordion>
      )}
      {openCompleteApportionment ? (
        <AddRefurbishApportionmentModal
          initialValue={Number(idRefurbish)}
          onSubmit={confirmCompleteApportionment}
          disableButton={!refurbishesSplit?.length}
          onClose={() => setOpenCompleteApportionment(false)}
          hasAddLibrary={paymentItems?.some(item => !item?.idItem)}
          loading={loadingLibraryItems}
          onlySubmit
          isPayment
        />
      ) : null}
      {apportionmentDrawer?.open && apportionmentDrawer?.type === 'complete' ? (
        <CompleteApportionmentModal
          refurbishes={objectRefurbishes}
          open={apportionmentDrawer?.open && apportionmentDrawer?.type === 'complete'}
          onClose={() => cancelCompleteApportionment()}
          onSubmit={handleApportionment}
          completeApportionmentList={completeApportionmentList}
          isEdit={!!id}
          hasPaidInstallment={hasPaidInstallment}
          clearStates={clearStates}
          isOrder={!!idOrder || isOrderPayment}
        />
      ) : null}
    </>
  );
};

export default PaymentFormItem;
