import { Checkbox } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { Div, spaces, fonts, colors } from '../../styles/style';
import Label from '../Label/Label';

const CheckBox = styled(Checkbox)`
  ${props => !props.noMargin && `margin-bottom: ${spaces.space0};`}
`;

const CheckboxAutoCode = ({ value, setCheckedValue = f => f, setField = f => f, noMargin, ...props }) => {
  return (
    <Div gap={spaces.space1} {...props}>
      <CheckBox
        id="form-auto-code-checkbox"
        checked={value}
        onChange={e => {
          setCheckedValue(prev => !prev);
          setField(e.target.checked);
        }}
        noMargin={noMargin}
      />
      <Label color={colors.neutral600} marginBottom={0} fontWeight={fonts.weight500}>
        Auto
      </Label>
    </Div>
  );
};

CheckboxAutoCode.propTypes = {
  value: PropTypes.instanceOf(Object),
  setCheckedValue: PropTypes.func,
  setField: PropTypes.func,
  paddingTop: PropTypes.string,
  noMargin: PropTypes.bool,
  containerMargin: PropTypes.string
};

export default CheckboxAutoCode;
