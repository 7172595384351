import { useEffect, useState } from 'react';
import { v4 as uuidV4 } from 'uuid';

import useCRUD from './useCRUD';
import { getMinutesFromHHMM } from '../lib/helpers/helper';

const useTemplateTaskDrawer = ({
  data,
  setIsSubmitting,
  setHasChanges,
  setSelectedItems,
  afterSubmit,
  ganttInstance
}) => {
  const [ganttChanges, setGanttChanges] = useState({
    updatedLinks: {},
    updatedTasks: {},
    deletedLinks: {},
    createdLinks: {},
    deletedTasks: {}
  });

  const [taskIsGoingToBeDeleted, setTaskIsGoingToBeDeleted] = useState(false);

  const { handleUpdate } = useCRUD({
    model: 'task',
    pathOptions: `/bulkUpdateWithPlanningLinks`,
    immediatelyLoadData: false
  });

  const prepareData = parsedData => {
    const { responsible, refurbish, refurbishStep, templateStep, user, estimativeDuration, ...rest } = parsedData;

    let estimativeDurationInMinutes = 0;

    if (estimativeDuration && estimativeDuration !== '') {
      estimativeDurationInMinutes = getMinutesFromHHMM(estimativeDuration);
    }

    return {
      ...rest,
      idResponsible: responsible?.id || null,
      idTemplateStep: templateStep || 1,
      estimativeDuration: estimativeDurationInMinutes
    };
  };

  const handleSubmit = submitData => {
    setIsSubmitting(true);
    const preparedData = prepareData({ ...submitData, isCurrentTask: true });

    const payload = {
      ...ganttChanges,
      updatedTasks: {
        [data?.id]: preparedData
      }
    };

    handleUpdate({
      values: { ...payload, idTemplate: data?.idTemplate, from: 'Drawer', isTemplate: true },
      refresh: false
    })
      .then(resp => {
        afterSubmit(resp);
        return resp;
      })
      .catch(error => {
        setIsSubmitting(false);
        setHasChanges(false);
        throw error;
      });
  };

  const updateLink = (link, updates) => {
    const updatedItem = { ...link, ...updates };
    setGanttChanges(prev => {
      if (prev.createdLinks[link?.id]) {
        return {
          ...prev,
          createdLinks: { ...prev.createdLinks, [link?.id]: updatedItem }
        };
      }
      return {
        ...prev,
        updatedLinks: { ...prev.updatedLinks, [link?.id]: updatedItem }
      };
    });

    setHasChanges(true);
    setSelectedItems(prev =>
      prev?.map(item => {
        if (item?.id === link?.id) {
          return updatedItem;
        }
        return item;
      })
    );
  };

  const removeLink = link => {
    setGanttChanges(prev => {
      const { updatedLinks, createdLinks, deletedLinks, ...rest } = prev;
      const newUpdatedLinks = { ...updatedLinks };
      const newCreatedLinks = { ...createdLinks };
      const newDeletedLinks = { ...deletedLinks };

      if (createdLinks[link?.id]) {
        // If the link was in createdLinks, just remove it from there
        delete newCreatedLinks[link?.id];
      } else {
        // If it wasn't in createdLinks, remove from updatedLinks and add to deletedLinks
        delete newUpdatedLinks[link?.id];
        newDeletedLinks[link?.id] = link;
      }

      return {
        ...rest,
        updatedLinks: newUpdatedLinks,
        createdLinks: newCreatedLinks,
        deletedLinks: newDeletedLinks
      };
    });

    setHasChanges(true);
    setSelectedItems(prev => prev.filter(item => item?.id !== link?.id));
  };

  const createLink = selectedItemId => {
    setHasChanges(true);

    const linkId = uuidV4();

    const newLink = {
      source: Number(selectedItemId),
      target: data.id,
      type: ganttInstance.config.links.finish_to_start,
      lag: null,
      id: linkId
    };

    const newItem = {
      source: Number(selectedItemId),
      lag: null,
      target: data?.id,
      type: ganttInstance.config.links.finish_to_start,
      id: linkId
    };
    setSelectedItems(prev => [...prev, newItem]);
    setGanttChanges(prev => ({
      ...prev,
      createdLinks: { ...prev.createdLinks, [linkId]: newLink }
    }));
  };

  useEffect(() => {
    if (!ganttInstance || !data) return;

    const handleLinkDelete = (linkId, link) => {
      setGanttChanges(prev => {
        const { updatedLinks, createdLinks, deletedLinks, ...rest } = prev;
        const newUpdatedLinks = { ...updatedLinks };
        const newCreatedLinks = { ...createdLinks };
        const newDeletedLinks = { ...deletedLinks };

        if (createdLinks[linkId]) {
          // If the link was in createdLinks, just remove it from there
          delete newCreatedLinks[linkId];
        } else {
          // If it wasn't in createdLinks, remove from updatedLinks and add to deletedLinks
          delete newUpdatedLinks[linkId];
          newDeletedLinks[linkId] = link;
        }

        return {
          ...rest,
          updatedLinks: newUpdatedLinks,
          createdLinks: newCreatedLinks,
          deletedLinks: newDeletedLinks
        };
      });
      setHasChanges(true);
    };

    const handleTaskDelete = taskId => {
      setGanttChanges(prev => {
        const { updatedTasks, deletedTasks, ...rest } = prev;
        return {
          ...rest,
          updatedTasks: {},
          deletedTasks: { [taskId]: true }
        };
      });
      setTaskIsGoingToBeDeleted(true);
      setHasChanges(true);
    };

    ganttInstance.attachEvent('onAfterLinkDelete', handleLinkDelete);
    ganttInstance.attachEvent('onAfterTaskDelete', handleTaskDelete);

    // eslint-disable-next-line consistent-return
    return () => {
      ganttInstance.detachEvent('onAfterLinkDelete');
      ganttInstance.detachEvent('onAfterTaskDelete');
    };
  }, [ganttInstance, data]);

  const handleSubmitDelete = () => {
    setIsSubmitting(true);

    const payload = {
      ...ganttChanges
    };
    handleUpdate({
      values: { ...payload, idTemplate: data?.idTemplate, isTemplate: true, from: 'Drawer' },
      refresh: false
    })
      .then(resp => {
        afterSubmit(resp);
        return resp;
      })
      .catch(error => {
        setIsSubmitting(false);
        setHasChanges(false);
        throw error;
      });
  };

  useEffect(() => {
    if (taskIsGoingToBeDeleted) {
      handleSubmitDelete();
      setTaskIsGoingToBeDeleted(false);
    }
  }, [taskIsGoingToBeDeleted]);

  const removeTask = () => {
    if (ganttInstance && data.id) {
      ganttInstance.deleteTask(Number(data.id));
    }
  };

  return {
    createLink,
    removeLink,
    updateLink,
    handleSubmit,
    ganttInstance,
    removeTask
  };
};

export default useTemplateTaskDrawer;
