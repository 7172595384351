import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Div, fonts, spaces } from '../../styles/style';
import { Paragraph } from '../Text/Text';
import Select from '../Select/Select';
import ConfirmModal from './ConfirmModal';
import useCRUD from '../../_Hooks/useCRUD';
import WarningBar from '../Alert/WarningBar';

const AddRefurbishApportionmentModal = ({
  onClose,
  onSubmit,
  customOptions,
  refurbishList,
  initialValue,
  hasAddLibrary,
  hasSplitOrderPermission: _hasSplitOrderPermission,
  isPayment,
  texts = {
    model: 'despesa',
    paragraph: 'Escolha os projetos para adicionar ao rateio dos itens da despesa.',
    selectLabel: 'Projeto/Oportunidade'
  },
  ...props
}) => {
  const hasSplitOrderPermission = _hasSplitOrderPermission || isPayment;

  const [refurbishesSplit, setRefurbishesSplit] = useState(initialValue ? [initialValue] : []);

  const { handleGet } = useCRUD({
    model: 'refurbish',
    immediatelyLoadData: false
  });

  return (
    <ConfirmModal
      {...props}
      cancelText="Voltar"
      modalWidth={500}
      onClose={() => {
        onClose();
      }}
      title="Selecionar projetos"
      disableButton={!refurbishesSplit?.length}
      onSubmit={() => {
        if (refurbishesSplit?.length === 1 && initialValue) {
          return handleGet({
            refetchPathOptions: `/${initialValue}`
          }).then(res => {
            return onSubmit([res]);
          });
        }
        return onSubmit(refurbishesSplit);
      }}
    >
      <Div gap={spaces.space2} direction="column">
        {hasAddLibrary && (
          <WarningBar
            width="100%"
            warning={`Há itens na ${texts.model} que não estão vinculados à sua biblioteca.
              Ao confirmar, os itens serão salvos na sua biblioteca.`}
          />
        )}
        <Div $fullWidth height="130px" align="baseline" gap={spaces.space3} direction="column">
          <Paragraph weight={fonts.weight500}>
            {hasSplitOrderPermission ? texts.paragraph : texts.paragraphBeta}
          </Paragraph>
          <Div $fullWidth align="baseline" direction="column">
            <Paragraph type="small" weight={fonts.weight500}>
              {texts.selectLabel}
            </Paragraph>
            <Select
              dropdownStyle={{ zIndex: 1300 }}
              fullWidth
              multiple={hasSplitOrderPermission}
              name="refurbish-split"
              id="refurbishSplit"
              model="refurbish"
              modelOptions={
                customOptions || {
                  where: { isActive: true, id: { notIn: refurbishList } },
                  order: [['name', 'asc']]
                }
              }
              placeholder="Selecione"
              value={hasSplitOrderPermission ? refurbishesSplit : refurbishesSplit[0]?.id}
              onChange={(_, obj) => setRefurbishesSplit(hasSplitOrderPermission ? obj : [obj])}
              showArrow
              showSearch
              noHeight
              itemsToRemove={refurbishList}
              isChangeableOptions={!initialValue}
              multipleSelectPropsInitialValue={{ disabled: true }}
              keepDisabled
              sendFullObject
            />
          </Div>
        </Div>
      </Div>
    </ConfirmModal>
  );
};

AddRefurbishApportionmentModal.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  customOptions: PropTypes.instanceOf(Object),
  refurbishList: PropTypes.instanceOf(Array),
  initialValue: PropTypes.instanceOf(Array),
  hasAddLibrary: PropTypes.bool,
  hasSplitOrderPermission: PropTypes.bool,
  texts: PropTypes.instanceOf(Object),
  props: PropTypes.instanceOf(Object),
  isPayment: PropTypes.bool
};

export default AddRefurbishApportionmentModal;
