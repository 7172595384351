import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowLeft,
  faEllipsis,
  faShareNodes,
  faEye,
  faTrashCan,
  faXmark,
  faCopy,
  faLink
} from '@fortawesome/pro-regular-svg-icons';
import { Menu } from 'antd';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import useViewport from '../../_Hooks/useViewport';
import { Paragraph, Subtitle, Title } from '../Text/Text';
import Drawer from './Drawer';
import { colors, Div, spaces, fonts } from '../../styles/style';
import ItemAttachmentContent from '../Content/ItemAttachmentContent';
import Modal from '../Modal/Modal';
import Button from '../Button/Button';
import CopyLinkButton from '../Button/CopyLinkButton';
import useCRUD from '../../_Hooks/useCRUD';
import { TaskMappingV2, TaskSchemaV2 } from '../../lib/mapping/Form/taskSchemaV2';
import FormV2 from '../Form/FormV2';
import ConfirmModal from '../Modal/ConfirmModal';
import useTemplateTaskDrawer from '../../_Hooks/useTemplateTaskDrawer';
import useTaskGantt from '../../_Hooks/useTaskGantt';
import { hasPermission } from '../../routes/Common/PrivateRoute';
import AnalyticsServiceFactory from '../../lib/analytics/analytics';
import Watcher from '../Watcher/Watcher';
import Checklist from '../Checklist/Checklist';
import useTaskDrawer from '../../_Hooks/useTaskDrawer';
import EditableInput from '../Input/EditableInput';
import useReplaceUrl from '../../_Hooks/useReplaceUrl';
import { getDateWithCustomHours, toHHMM } from '../../lib/helpers/helper';
import PredecessorsSection from '../Sections/PredecessorsSection';
import RichTextEditor from '../RichTextEditor/RichTextEditor';
import DrawerTabs from './DrawerComponents/DrawerTabs';
import DrawerHeaderComponents from './DrawerComponents/DrawerHeaderComponents';
import DrawerDropdownShareMenu from './DrawerComponents/DrawerDropdownShareMenu';

const ContainerDropdownTreeSelect = styled(Div)`
  .ant-tree-select-dropdown {
    padding: 0px;
  }

  .ant-select-tree-treenode.ant-select-tree-treenode-switcher-open {
    &:hover {
      background-color: ${colors.neutral75};
    }
  }

  .ant-select-tree .ant-select-tree-node-content-wrapper {
    &:hover {
      background-color: ${colors.neutral75};
    }
  }

  .ant-select-tree .ant-select-tree-node-content-wrapper {
    transition: all 0s !important;
  }

  .ant-select-tree .ant-select-tree-treenode {
    padding: 0px 4px !important;
  }

  .ant-select-tree .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
    background-color: ${colors.neutral75} !important;
  }

  .ant-select-tree-treenode.ant-select-tree-treenode-switcher-open.ant-select-tree-treenode-selected {
    background-color: ${colors.neutral75} !important;
  }
`;

const ScheduleDrawer = ({
  open,
  onClose,
  tab = '0',
  desktopWidth = '780px',
  id,
  dataGantt,
  afterSubmit = f => f,
  isTemplate = false,
  idReference,
  generalTask,
  copyLinkParam = 't',
  initialData,
  closeAfterSubmit = false,
  creatingTask = false
}) => {
  const { isMobile } = useViewport(window.innerWidth);
  const _isMobile = isMobile();
  const { plans, permissions } = useSelector(state => state.setup);
  const { userType, taskStatus: taskStatusEnum, priority: priorityEnum } = useSelector(state => state.setup.enums);
  const { user } = useSelector(state => state.authReducer);
  const [files, setFiles] = useState([]);

  const { returnToOriginalUrl } = useReplaceUrl({
    preventTrigger: !copyLinkParam || isTemplate || !id,
    urlToReplace: `/${copyLinkParam}/${id}`
  });

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);

  const isCustomer = user.userType === userType.customer.value;

  const [showComponents, setShowComponents] = useState({
    showMobileOptionsModal: false,
    showShareDropdown: false,
    showWatchersDropdown: false,
    showSelectPredecessors: false,
    showDeleteModal: false,
    showDuplicateModal: false
  });
  const [selectedTab, setSelectedTab] = useState(!isCustomer ? tab : '1');

  const analytics = AnalyticsServiceFactory();

  const [description, setDescription] = useState('');
  const [data, setData] = useState(null);
  const [_checklist, setChecklist] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [newFormValues, setNewFormValues] = useState(null);
  const [selectedIdReferenceFromForm, setSelectedIdReferenceFromForm] = useState(idReference);
  const [isLoading, setIsLoading] = useState(true);
  const [taskHasLinks, setTaskHasLinks] = useState(false);
  const [internalDataGantt, setInternalDataGantt] = useState({ data: [], links: [], resources: [] });

  const onPerformHoursDropdownClose = totalPerformedHours => {
    setNewFormValues(prev => ({ ...prev, totalPerformedHours }));
  };

  const model = isTemplate ? 'templateStep' : 'refurbishStep';
  const referenceKey = isTemplate ? 'idTemplate' : 'idRefurbish';
  const stepIdKey = isTemplate ? 'idTemplateStep' : 'idRefurbishStep';
  const options = {};

  const { data: refurbishData, loading: loadingRefurbish } = useCRUD({
    model: 'refurbish',
    pathOptions: selectedIdReferenceFromForm ? `/${selectedIdReferenceFromForm}` : '',
    options: {
      attributes: ['id', 'name', 'workingDays', 'itemsToShare', 'clientColumns'],
      include: [{ model: 'user', attributes: ['id', 'name'] }, 'refurbishDayoffs']
    },
    immediatelyLoadData: !!selectedIdReferenceFromForm && !isTemplate
  });

  const { data: taskData, loading: loadingTask } = useCRUD({
    model: 'task',
    pathOptions: id ? `/${id}` : '',
    options: {
      attributes: [
        'id',
        'name',
        !isTemplate && 'startDate',
        !isTemplate && 'endDate',
        'status',
        'priority',
        'checklist',
        'description',
        'idResponsible',
        'estimativeDuration',
        'realDuration',
        'totalPerformedHours',
        'duration',
        referenceKey,
        stepIdKey
      ],
      ...options,
      include: [
        ...(options?.include || []),
        'files',
        'performedHours',
        { model, attributes: ['id', 'name', 'color'] },
        { model: 'user', attributes: ['id', 'name', 'avatarFullpath'] },
        ...(!isTemplate ? ['files'] : [])
      ]
    },
    immediatelyLoadData: !!id
  });

  useEffect(() => {
    if (loadingTask || loadingRefurbish) return;

    const defaultData = {
      id: -1,
      name: '',
      startDate: null,
      endDate: null,
      status: taskStatusEnum.planned.id,
      priority: priorityEnum.low.id,
      checklist: null,
      description: null,
      idResponsible: null,
      estimativeDuration: null,
      realDuration: null,
      totalPerformedHours: null,
      duration: null,
      idParent: 1,
      idRefurbish: selectedIdReferenceFromForm || null,
      idRefurbishStep: 1,
      refurbishStep: { color: colors.neutral400, name: 'Geral', id: 1 },
      ...initialData
    };

    if (taskData || isTemplate) {
      setData({ ...taskData, refurbish: refurbishData });
    } else {
      setData({ ...defaultData, refurbish: refurbishData });
    }
  }, [taskData, refurbishData, loadingTask, loadingRefurbish]);

  const { checklist } = data || {};
  const totalItemsChecklist = _checklist?.length;
  const totalItemsChecklistDone = _checklist?.filter(item => item.checked).length;

  const transformChecklist = list => {
    const orderList = list.map((item, index) => ({ ...item, order: item?.order || index + 1 }));

    return orderList.sort((a, b) => parseFloat(a.order) - parseFloat(b.order));
  };

  useEffect(() => {
    setChecklist(transformChecklist(checklist || []));
  }, [checklist]);

  const { handleCreate: duplicateTask } = useCRUD({
    model: 'task/duplicate',
    immediatelyLoadData: false
  });

  const { ganttInstance, transformAndFindUsers, transformData } = useTaskGantt({
    idRefurbish: data?.refurbish?.id,
    workingDays: data?.refurbish?.workingDays,
    dayoffs: data?.refurbish?.refurbishDayoffs,
    tasks: internalDataGantt
  });

  const { handleGet: getItems } = useCRUD({
    model,
    immediatelyLoadData: false
  });

  const { handleGet: handleGetTaskLink } = useCRUD({
    model: 'taskLink',
    options: { where: { selectedIdReferenceFromForm } },
    immediatelyLoadData: false
  });

  const hookToUse = useMemo(() => (isTemplate ? useTemplateTaskDrawer : useTaskDrawer), [isTemplate]);
  const propsForTrack = { idCompany: user?.idCompany, idRefurbish: selectedIdReferenceFromForm, idUser: user?.id };

  const { createLink, removeTask, removeLink, updateLink, handleSubmit, onChangeDateRelatedField } = hookToUse({
    data: { ...data, idRefurbish: selectedIdReferenceFromForm },
    setNewFormValues,
    newFormValues,
    setIsSubmitting: setIsLoading,
    afterSubmit,
    setHasChanges,
    setSelectedItems,
    ganttInstance
  });

  useEffect(() => {
    if (!ganttInstance) return;

    ganttInstance.plugins({
      auto_scheduling: true
    });
  }, [ganttInstance]);

  useEffect(() => {
    if (!ganttInstance || internalDataGantt.data.length === 0) return;
    ganttInstance.clearAll();
    ganttInstance.parse(internalDataGantt);
    if (creatingTask && !generalTask) {
      ganttInstance.addTask(transformData({ ...data, isRoot: false, parentId: 1 }));
    }
  }, [internalDataGantt, selectedIdReferenceFromForm]);

  useEffect(() => {
    if (!data || !dataGantt) return;
    const ganttTasks = dataGantt.data || [];
    const links = dataGantt.links || [];

    const promise = ganttTasks?.length
      ? Promise.resolve([ganttTasks, links])
      : Promise.all([
          getItems({
            refetchOptions: {
              where: selectedIdReferenceFromForm
                ? { or: [{ [referenceKey]: selectedIdReferenceFromForm }, { id: 1 }] }
                : { id: 1 },
              ...(selectedIdReferenceFromForm && {
                include: [
                  {
                    model: 'task',
                    include: [
                      'user',
                      'performedHours',
                      {
                        model: 'TaskLink',
                        as: 'targetLinks'
                      }
                    ],
                    required: false,
                    where: { [referenceKey]: selectedIdReferenceFromForm }
                  }
                ]
              }),
              order: selectedIdReferenceFromForm
                ? [
                    ['order', 'ASC NULLS FIRST'],
                    ['tasks', 'order']
                  ]
                : [['order', 'ASC NULLS FIRST']]
            }
          }),
          selectedIdReferenceFromForm
            ? handleGetTaskLink({ refetchOptions: { where: { [referenceKey]: selectedIdReferenceFromForm } } })
            : Promise.resolve([])
        ]).then(([_ganttasks, _links]) => {
          let processedGanttTasks = _ganttasks;
          if (generalTask || data?.id === -1) {
            processedGanttTasks = _ganttasks.map(task => {
              if (task.id === 1) {
                return {
                  ...task,
                  tasks: [...(task.tasks || []), { ...data, idRefurbishStep: 1 }]
                };
              }
              return task;
            });
          }
          const result = transformAndFindUsers(
            processedGanttTasks,
            null,
            null,
            false,
            {
              ...(!isTemplate && data?.refurbish?.plBasedDate && { type: 'task' })
            },
            'tasks'
          );
          return [result?.data, _links];
        });

    promise.then(([_tasks, _links]) => {
      const newSelectedItems = [];

      // Process links to build newSelectedItems
      _links.forEach(link => {
        if (Number(link.target) === data.id) {
          setTaskHasLinks(true);
          const sourceTask = _tasks.find(task => task.id === Number(link.source));
          if (sourceTask) {
            newSelectedItems.push({
              source: Number(link.source),
              type: link.type || ganttInstance.config.links.finish_to_start,
              lag: link.lag,
              id: link.id
            });
          }
        }
        if (Number(link.source) === data.id) {
          setTaskHasLinks(true);
        }
      });

      setInternalDataGantt({
        data: _tasks,
        links: _links
      });
      setSelectedItems(newSelectedItems);
    });
    setIsLoading(false);
  }, [data, dataGantt, selectedIdReferenceFromForm]);

  useEffect(() => {
    if (data) {
      setFiles(data.files || []);
      setDescription(data.description || '');
      if (isCustomer && data?.refurbish?.itemsToShare?.timeline) setSelectedTab(tab);
    }
  }, [data]);

  const handleClose = () => {
    if (hasChanges) {
      setShowConfirmModal(true);
    } else {
      onClose({});
      returnToOriginalUrl();
    }
  };

  const handleConfirmModalClose = (_, opts) => {
    if (opts?.discard) {
      setHasChanges(false);
      onClose();
      returnToOriginalUrl();
    } else {
      setShowConfirmModal(false);
    }
  };

  const { list, handleGet: getList } = useCRUD({
    model,
    immediatelyLoadData: false
  });

  useEffect(() => {
    if (data && selectedIdReferenceFromForm) {
      getList({
        refetchOptions: {
          where: { or: [{ id: 1 }, { [referenceKey]: selectedIdReferenceFromForm }] },
          attributes: ['name', 'id', 'color', 'startDate', 'endDate'],
          include: [
            {
              model: 'task',
              attributes: ['name', 'id', 'color', stepIdKey, 'startDate', 'endDate', 'duration', 'status', 'priority'],
              include: [
                {
                  model: 'TaskLink',
                  as: 'targetLinks'
                }
              ],
              required: false,
              where: { [referenceKey]: selectedIdReferenceFromForm }
            }
          ],
          order: [
            ['order', 'ASC NULLS FIRST'],
            ['tasks', 'order']
          ]
        }
      });
    }
  }, [data, selectedIdReferenceFromForm]);

  const handleShareWhatsapp = () => {
    analytics.track({
      event: 'construct-schedule-item-shared',
      eventProperties: { ...propsForTrack, type: 'Whatsapp' }
    });
    window.open(
      // eslint-disable-next-line max-len
      `https://wa.me/?text=Olá%20veja%20esse%20item%20do%20seu%20projeto%20na%20plataforma:%20${window.location.origin}/t/${id}`,
      '_blank'
    );
  };

  const editMenu = (
    <Menu style={{ padding: `${spaces.space1} ${spaces.space2} ${spaces.space1} 0` }}>
      <Menu.Item style={{ padding: `0` }}>
        <Button
          onClick={() => setShowComponents({ ...showComponents, showDuplicateModal: true })}
          id="duplicate-task"
          text
        >
          <FontAwesomeIcon icon={faCopy} />
          <Paragraph type="small">Duplicar</Paragraph>
        </Button>
      </Menu.Item>
      <Menu.Item style={{ padding: `0` }}>
        <Button
          type="danger"
          onClick={() => setShowComponents({ ...showComponents, showDeleteModal: true })}
          id="delete-task"
          text
        >
          <FontAwesomeIcon icon={faTrashCan} />
          <Paragraph type="small">Excluir</Paragraph>
        </Button>
      </Menu.Item>
    </Menu>
  );

  const headerIcons = [
    {
      icon: faEye,
      component: <Watcher idTask={id} idCompany={user?.idCompany} />,
      showOnMobile: true,
      shouldDisplayWhen: data?.id !== -1 && hasPermission(user, ['item'], plans, permissions) && !isCustomer
    },
    {
      icon: faShareNodes,
      title: 'Compartilhar',
      menu: () => (
        <DrawerDropdownShareMenu
          id={id}
          analyticsProps={propsForTrack}
          customEvent="construct-schedule-item-shared"
          urlContext="t"
        />
      ),
      isOpen: showComponents?.showShareDropdown,
      onClick: v => setShowComponents({ ...showComponents, showShareDropdown: v }),
      shouldDisplayWhen: data?.id !== -1 && !isCustomer
    },
    {
      icon: faEllipsis,
      title: 'Mais opções',
      menu: editMenu,
      isOpen: showComponents?.showMoreOptions,
      onClick: v => setShowComponents({ ...showComponents, showMoreOptions: v }),
      shouldDisplayWhen: data?.id !== -1 && !isCustomer
    },
    {
      icon: faEllipsis,
      title: 'Mais opções',
      onlyMobile: true,
      onClick: () => setShowComponents({ ...showComponents, showMobileOptionsModal: true }),
      shouldDisplayWhen: data?.id !== -1 && !isCustomer
    },
    { icon: faXmark, title: 'Fechar', onClick: handleClose }
  ];

  const hasTimelinePermission =
    (isCustomer && data?.refurbish?.itemsToShare?.timeline) ||
    (!isCustomer && hasPermission(user, ['timeline'], plans, permissions));

  const sections = [
    {
      key: '0',
      title: 'Checklist',
      component: (
        <Div direction="column" align="flex-start" $fullWidth gap={spaces.space1}>
          <Div gap={spaces.space0} align="center" padding={`${spaces.space2} ${spaces.space2} 0 ${spaces.space2}`}>
            <Subtitle>Checklist</Subtitle>
            {_checklist?.length > 0 && (
              <Paragraph type="small" weight={fonts.weight500}>
                ({totalItemsChecklistDone}/{totalItemsChecklist})
              </Paragraph>
            )}
          </Div>
          <Checklist
            list={_checklist ? [..._checklist] : []}
            onChange={_list => {
              if (!isCustomer) {
                setHasChanges(true);
                setChecklist(transformChecklist(_list || []));
              }
            }}
            readOnly={isCustomer}
          />
        </Div>
      )
    },
    ...(hasTimelinePermission && selectedIdReferenceFromForm
      ? [
          {
            key: '1',
            title: 'Dependências',
            component: (
              <PredecessorsSection
                showComponents={showComponents}
                setShowComponents={setShowComponents}
                selectedItems={selectedItems}
                list={list}
                stepIdKey={stepIdKey}
                childrenKey="tasks"
                id={data?.id}
                updateLink={updateLink}
                removeLink={removeLink}
                createLink={createLink}
                ganttInstance={ganttInstance}
              />
            )
          }
        ]
      : []),
    ...(!isTemplate
      ? [
          {
            key: '2',
            title: 'Anexos',
            component: (
              <Div padding={spaces.space2} width="100%">
                <ItemAttachmentContent
                  readOnly={isCustomer}
                  hideTitle
                  hasDescription={false}
                  data={{ files }}
                  setFiles={setFiles}
                />
              </Div>
            )
          }
        ]
      : [])
  ];

  const [name, setName] = useState(data?.name);

  useEffect(() => {
    if (data?.name) setName(data?.name);
  }, [data?.name]);

  return (
    <>
      <Drawer
        open={open}
        formId="planningForm"
        title={
          <Div gap={spaces.space1}>
            {_isMobile && (
              <Div onClick={handleClose} padding={spaces.space1}>
                <FontAwesomeIcon icon={faArrowLeft} />
              </Div>
            )}
            <Title color={colors.neutral700}>Tarefa</Title>
          </Div>
        }
        onClose={handleClose}
        padding="0px"
        width={_isMobile ? '100%' : desktopWidth}
        closeIcon={false}
        extra={<DrawerHeaderComponents components={headerIcons} />}
        loading={isLoading || !data?.id}
        submitText="Salvar"
        showSubmit={!isCustomer}
      >
        {!isLoading && (
          <Div direction="row" $fullHeight>
            <Div $minWidth={_isMobile ? '100%' : desktopWidth} direction="column" $fullHeight align="flex-start">
              <ContainerDropdownTreeSelect id="containerTreeSelect" />
              <EditableInput
                value={name}
                id="input-name"
                onChange={newName => {
                  setName(newName);
                  setHasChanges(true);
                }}
                disabled={isCustomer}
                initEdit={data?.id === -1}
                placeholder={data?.id === -1 ? 'Nome da tarefa' : '-'}
                className="form-component-name"
              />
              <Div display="block" padding={spaces.space2} $fullWidth>
                <FormV2
                  id="planningForm"
                  newValues={newFormValues}
                  data={{
                    ...data,
                    ...(data?.startDate && { startHours: dayjs(data?.startDate).format('HH:mm') }),
                    ...(data?.endDate && { endHours: dayjs(data?.endDate).format('HH:mm') }),
                    responsible: data?.user,
                    refurbish:
                      generalTask && data?.refurbish?.id
                        ? { label: data?.refurbish?.name, id: data?.refurbish?.id }
                        : null,
                    estimativeDuration: toHHMM(data?.estimativeDuration),
                    duration:
                      !data?.duration && data?.startDate && data?.endDate
                        ? ganttInstance.calculateDuration({
                            start_date: getDateWithCustomHours(data?.startDate, { hours: 3, minutes: 0 }),
                            end_date: getDateWithCustomHours(data?.endDate, { hours: 15, minutes: 0 })
                          })
                        : data?.duration,
                    refurbishStep: data?.refurbishStep
                      ? {
                          value: data?.refurbishStep.id,
                          title: data?.refurbishStep.name,
                          color: data?.refurbishStep.color
                        }
                      : null,
                    templateStep: data?.templateStep
                      ? {
                          value: data?.templateStep.id,
                          title: data?.templateStep.name,
                          color: data?.templateStep.color
                        }
                      : null
                  }}
                  schema={TaskSchemaV2}
                  onValueChanged={setHasChanges}
                  onFormChange={({ refurbish }) => {
                    if (refurbish?.value && refurbish?.value !== selectedIdReferenceFromForm) {
                      setSelectedIdReferenceFromForm(refurbish?.value);
                    }
                  }}
                  mapping={TaskMappingV2({
                    idRefurbish: selectedIdReferenceFromForm,
                    parent: data?.parent,
                    supplier: data?.supplier,
                    onChangeDateRelatedField,
                    workDays: data?.refurbish?.workingDays,
                    dayoffs: data?.refurbish?.refurbishDayoffs,
                    isTemplate,
                    canChangeRefurbish: generalTask,
                    idTemplate: idReference,
                    idTask: id,
                    taskAlreadyHasLinks: taskHasLinks || selectedItems?.length > 0,
                    totalPerformedHours: data?.totalPerformedHours,
                    onPerformHoursDropdownClose
                  })}
                  onSubmit={submitData => {
                    handleSubmit(
                      {
                        ...submitData,
                        ...newFormValues,
                        description,
                        name,
                        checklist: _checklist,
                        [stepIdKey]: submitData?.[model]?.id,
                        ...(generalTask && {
                          idRefurbish: selectedIdReferenceFromForm
                        })
                      },
                      files
                    );
                    closeAfterSubmit && onClose({});
                    returnToOriginalUrl();
                  }}
                  displayButtons={false}
                  readOnly={isCustomer}
                />
                <Div direction="column" gap={spaces.space1} align="flex-start" margin={`${spaces.space2} 0 0 0`}>
                  {_isMobile ? <Subtitle>Descrição</Subtitle> : null}
                  {isCustomer ? (
                    <Paragraph>{description}</Paragraph>
                  ) : (
                    <RichTextEditor
                      id="schedule-drawer-description-editor"
                      resize
                      plugins={['autoresize']}
                      statusbar={false}
                      onChange={value => {
                        setDescription(value);
                        setHasChanges(true);
                      }}
                      value={description}
                      placeholder="Adicione uma descrição..."
                    />
                  )}
                </Div>
              </Div>
              <DrawerTabs sections={sections} selectedTab={selectedTab} onTabChange={setSelectedTab} />
            </Div>
          </Div>
        )}
      </Drawer>
      {showConfirmModal && (
        <ConfirmModal
          title="Alterações não salvas"
          text="Você possui alterações que não foram salvas. Salve ou descarte as alterações para continuar."
          formId="planningForm"
          alertInfo="Essa ação não poderá ser desfeita."
          submitText="Salvar"
          showDiscardButton
          onClose={handleConfirmModalClose}
        />
      )}
      {showComponents?.showMobileOptionsModal && (
        <Modal
          title="Opções"
          open={showComponents?.showMobileOptionsModal}
          hideFooter
          onClose={() => setShowComponents({ ...showComponents, showMobileOptionsModal: false })}
        >
          <CopyLinkButton
            customEvent="construct-schedule-item-shared"
            customEventProperties={{ ...propsForTrack, type: 'Copy' }}
            urlContext="o"
            idData={id}
            id="copy-link"
            text
            style={{ padding: `${spaces.space1} 0` }}
          >
            <Div width={spaces.space3} height={spaces.space3} justify="center">
              <FontAwesomeIcon icon={faLink} color={colors.neutral400} />
            </Div>
            <Paragraph type="small">Copiar link</Paragraph>
          </CopyLinkButton>
          <Button padding={`${spaces.space1} 0`} text onClick={handleShareWhatsapp}>
            <Div width={spaces.space3} height={spaces.space3} justify="center">
              <FontAwesomeIcon icon={faWhatsapp} color={colors.neutral400} />
            </Div>
            <Paragraph type="small">Compartilhar no Whatsapp</Paragraph>
          </Button>
          {hasPermission(user, ['item'], plans, permissions) && !isCustomer ? (
            <>
              <Button
                text
                padding={`${spaces.space1} 0`}
                onClick={() => setShowComponents({ ...showComponents, showDuplicateModal: true })}
                id="duplicate-task"
              >
                <Div width={spaces.space3} height={spaces.space3} justify="center">
                  <FontAwesomeIcon icon={faCopy} color={colors.neutral400} />
                </Div>
                <Paragraph type="small">Duplicar</Paragraph>
              </Button>
              <Button
                text
                type="danger"
                padding={`${spaces.space1} 0`}
                onClick={() => setShowComponents({ ...showComponents, showDeleteModal: true })}
                id="delete-task"
              >
                <FontAwesomeIcon icon={faTrashCan} />
                <Paragraph type="small">Excluir</Paragraph>
              </Button>
            </>
          ) : null}
        </Modal>
      )}
      {showComponents?.showDeleteModal && (
        <ConfirmModal
          text="Deseja realmente excluir essa tarefa?"
          onClose={() => setShowComponents({ ...showComponents, showDeleteModal: false })}
          onSubmit={() => {
            removeTask();
            returnToOriginalUrl();
          }}
        />
      )}
      {showComponents?.showDuplicateModal && (
        <ConfirmModal
          text="Deseja duplicar esta tarefa?"
          onClose={() => setShowComponents({ ...showComponents, showDuplicateModal: false })}
          onSubmit={() => {
            duplicateTask({
              values: { id },
              refresh: false
            }).then(response => {
              setShowComponents({ ...showComponents, showDuplicateModal: false });
              toast.success(`Tarefa ${response.name} duplicada com sucesso`);
              onClose();
            });
          }}
        />
      )}
    </>
  );
};

ScheduleDrawer.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  tab: PropTypes.string,
  desktopWidth: PropTypes.string,
  id: PropTypes.number,
  isTemplate: PropTypes.bool,
  dataGantt: PropTypes.instanceOf(Object),
  afterSubmit: PropTypes.func,
  idReference: PropTypes.number,
  copyLinkParam: PropTypes.string,
  generalTask: PropTypes.bool,
  initialData: PropTypes.instanceOf(Object),
  closeAfterSubmit: PropTypes.bool,
  creatingTask: PropTypes.bool
};

export default ScheduleDrawer;
