import { useEffect, useRef, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { v4 as uuidV4 } from 'uuid';

import {
  buildQueryString,
  getObjectWithSelectedFields,
  padLeft,
  validatePercentage,
  validatePurchaseTableItems
} from '../lib/helpers/helper';
import useCRUD from './useCRUD';
import useUrlParams from './useUrlParams';
import formatNumber from '../lib/helpers/formatNumber';
import { saveQuoteToPurchaseOrderIds } from '../store/quoteToPurchaseOrderIds/actions/action';
import {
  calculateTotalPercentageSplit,
  clearItemToApportionment,
  distributeExtraValuesByPercentage,
  distributeInstallmentsByPercentage,
  mergeItems,
  mountApportionmentItems
} from '../lib/helpers/payment';
import { formatAppropriation } from '../lib/helpers/order';

const _formatNumber = number => (number ? formatNumber(number) : null);

const usePurchase = ({
  model,
  modelOptions,
  list,
  setList,
  baseUrl,
  isReview,
  setModalProps,
  itemObjectsMap = {},
  setAddressFormValues,
  forceAddressFormUpdate,
  setLinkedSolicitation,
  setLinkedQuote,
  setExtraValues,
  itemSettersMap = {}
}) => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const { user = {} } = useSelector(state => state.authReducer) || {};
  const { purchaseSolicitationStatus, purchaseOrderStatus, purchaseQuoteStatus } = useSelector(
    state => state.setup.enums
  );

  const quoteToPurchaseOrderIds = useSelector(state => state.quoteToPurchaseOrderIds);

  const { foundParams } = useUrlParams({
    urlParams: ['projeto', 'solicitacao', 'cotacao', 'fornecedor', 'supplierReference']
  });

  const [idRefurbish, setIdRefurbish] = useState(null);
  const [canEditRefurbish, setCanEditRefurbish] = useState(false);
  const [canEditSupplier, setCanEditSupplier] = useState(true);

  const [purchaseFormValues, setPurchaseFormValues] = useState({ priority: 2 });
  const [hasObservation, setHasObservation] = useState(false);
  const [observation, setObservation] = useState('');
  const [idPurchase, setIdPurchase] = useState(null);
  const [idQuote, setIdQuote] = useState(null);
  const [loadingParams, setLoadingParams] = useState(false);

  // rateio - split ----------
  const tabEnum = {
    list: '1',
    totals: '2'
  };

  const { setCreatedItems } = itemSettersMap;

  const [addRefurbishModal, setAddRefurbishModal] = useState(null);
  const [listRefurbish, setListRefurbish] = useState([]);
  const [addItemType, setAddItemType] = useState(null);
  const [touched, setTouched] = useState(false);
  const [totals, setTotals] = useState(false);
  const [openAllRefurbishes, setOpenAllRefurbishes] = useState(false);
  const [purchaseList, setPurchaseList] = useState({});
  const [invalidPaymentItems, setInvalidPaymentItems] = useState({});
  const [removeRefurbishModal, setRemoveRefurbishModal] = useState(null);
  const [pressedButtonType, setPressedButtonType] = useState(null);
  const [showCatalogDrawer, setShowCatalogDrawer] = useState(false);
  const [itemsOnListMap, setItemsOnListMap] = useState({});
  const [apportionmentTotalsList, setApportionmentTotalsList] = useState([]);
  const [tabView, setTabView] = useState(tabEnum.list);
  const [mergedList, setMergedList] = useState([]);
  const [refurbishesAddress, setRefurbishesAddress] = useState({});
  const [newRefurbishes, setNewRefurbishes] = useState({});
  const [splitQuoteIds, setSplitQuoteIds] = useState({});

  const searchParams = new URLSearchParams(window.location.search);
  const isSplit = searchParams.get('isSplit');
  // --------------

  const forceFormUpdate = useRef(false);
  const preventUpdateWithRefurbishAddress = useRef(false);

  const { idCompany } = user || {};

  const stringsMap = {
    purchase: {
      idColumnName: 'idPurchase',
      childrenColumnName: 'purchaseItems',
      levelsColumnName: 'purchaseItemLevels',
      statusColumnName: 'idPurchaseStatus',
      dateColumnName: 'purchaseDate',
      baseName: 'solicitação',
      statusEnum: purchaseSolicitationStatus,
      codePrefix: 'SC',
      shortPath: 's',
      modalConfirmDelete: {
        text: 'Deseja realmente apagar essa solicitação?'
      }
    },
    order: {
      idColumnName: 'idOrder',
      childrenColumnName: 'orderItems',
      levelsColumnName: 'orderItemLevels',
      statusColumnName: 'idOrderStatus',
      dateColumnName: 'orderDate',
      baseName: 'ordem',
      statusEnum: purchaseOrderStatus,
      codePrefix: 'OC',
      shortPath: 'oc',
      modalConfirmDelete: {
        text: 'Ao excluir, a ordem de compra será removida permanente da sua conta e ninguém mais poderá acessá-la.',
        title: 'Apagar ordens de compras?',
        alertInfo: 'Os lançamentos financeiros vinculados também serão excluídos'
      }
    },
    quote: {
      idColumnName: 'idQuote',
      childrenColumnName: 'quoteItems',
      levelsColumnName: 'quoteItemLevels',
      statusColumnName: 'idQuoteStatus',
      dateColumnName: 'responseDate',
      baseName: 'cotação',
      statusEnum: purchaseQuoteStatus,
      codePrefix: 'QT',
      shortPath: 'qt',
      modalConfirmDelete: {
        text: 'Ao excluir, a ordem de compra será removida permanente da sua conta e ninguém mais poderá acessá-la.',
        title: 'Apagar cotação?'
      }
    }
  };

  const {
    idColumnName,
    childrenColumnName,
    levelsColumnName,
    statusColumnName,
    dateColumnName,
    baseName,
    statusEnum,
    codePrefix,
    shortPath,
    modalConfirmDelete
  } = stringsMap[model];

  let pathOptions = id ? `/${id}` : '';

  if (isSplit && id) {
    pathOptions = `/split/${id}`;
  }

  const { handleCreate: handleCreateLibraryItem } = useCRUD({
    model: 'refurbish-items',
    immediatelyLoadData: false
  });

  const { data, loading, handleGet, handleCreate, handleUpdate } = useCRUD({
    model,
    pathOptions,
    options: modelOptions,
    immediatelyLoadData: false
  });

  const { data: refurbish, handleGet: getRefurbish } = useCRUD({
    model: 'refurbish',
    immediatelyLoadData: false
  });

  const { handleGet: getPurchase } = useCRUD({
    model: 'purchase',
    immediatelyLoadData: false,
    options: {
      where: { idCompany },
      include: [
        'refurbish',
        {
          model: 'purchaseItems',
          include: [
            {
              model: 'unit',
              as: 'units'
            },
            'item',
            {
              model: 'purchaseItemLevel',
              include: ['refurbishItem'],
              attributes: ['id', 'name', 'price', 'quantity', 'percentage', 'idPurchaseItem', 'idRefurbishItem', 'type']
            }
          ]
        }
      ]
    }
  });

  const { handleGet: getQuote } = useCRUD({
    model: 'quote',
    immediatelyLoadData: false
  });

  const applyValuesInForm = ({ values, childrenName, levelsOriginName, levelsDestinationName }) => {
    setPurchaseFormValues({
      id: values?.id,
      priority: values?.priority,
      idResponsible: values?.idResponsible,
      idRefurbish: values?.idRefurbish,
      idSupplier: values?.idSupplier,
      name: values?.name,
      [dateColumnName]: values?.[dateColumnName],
      ...(values?.quoteReferenceSplit ? { quoteReferenceSplit: values?.quoteReferenceSplit } : {})
    });
    if (values?.observation) {
      setHasObservation(true);
      setObservation(values?.observation);
    }
    if (values?.idPurchase) {
      setIdPurchase(values?.idPurchase);
    }
    if (values?.idQuote) {
      setIdQuote(values?.idQuote);
    }
    if (values?.[childrenName]?.length) {
      setList(
        values?.[childrenName].map(item => ({
          ...item,
          [levelsDestinationName || levelsOriginName]: item?.[levelsOriginName]?.length
            ? item?.[levelsOriginName]?.map(childItem => ({
                ...childItem,
                idParent: item?.id
              }))
            : null
        }))
      );
    }
    forceFormUpdate.current = true;
  };

  useEffect(() => {
    if (foundParams?.projeto) {
      setIdRefurbish(Number(foundParams?.projeto));
      setPurchaseFormValues(prev => ({ ...prev, idRefurbish: Number(foundParams?.projeto) }));
      forceFormUpdate.current = true;
    }
    if (foundParams?.solicitacao || foundParams?.cotacao || foundParams.fornecedor) {
      setLoadingParams(true);
      if (foundParams?.solicitacao) setIdPurchase(Number(foundParams?.solicitacao));
      if (foundParams?.cotacao) setIdQuote(foundParams?.cotacao);
      if (foundParams?.fornecedor) setCanEditSupplier(false);

      const getFn = foundParams?.solicitacao ? getPurchase : getQuote;
      getFn({
        refetchPathOptions: `/${isSplit && !foundParams?.solicitacao ? 'split/' : ''}${foundParams?.solicitacao ||
          foundParams?.cotacao}`,
        ...(foundParams?.cotacao && {
          refetchOptions: {
            where: {
              idCompany,
              ...(isSplit
                ? {
                    ...(quoteToPurchaseOrderIds?.length ? { idItem: quoteToPurchaseOrderIds } : {}),
                    supplierReference: foundParams?.supplierReference
                  }
                : {})
            },
            include: [
              {
                model: 'purchase',
                attributes: ['id', 'code']
              },
              'refurbish',
              { model: 'supplier', where: { id: foundParams?.fornecedor }, attributes: ['id'] },
              {
                model: 'quoteItems',
                ...(quoteToPurchaseOrderIds?.length && { where: { id: quoteToPurchaseOrderIds } }),
                include: [
                  {
                    model: 'unit',
                    as: 'units'
                  },
                  'item',
                  {
                    model: 'quoteItemSuppliers',
                    required: false,
                    as: 'quoteItemSuppliers',
                    include: [{ model: 'quoteSuppliers', where: { idSupplier: foundParams?.fornecedor } }]
                  },
                  {
                    model: 'quoteItemLevel',
                    include: ['refurbishItem'],
                    attributes: [
                      'id',
                      'name',
                      'price',
                      'quantity',
                      'percentage',
                      'idQuoteItem',
                      'idRefurbishItem',
                      'type'
                    ]
                  }
                ]
              }
            ]
          }
        })
      }).then(response => {
        dispatch(saveQuoteToPurchaseOrderIds(null));
        if (!response?.id) {
          toast.error(`${foundParams?.solicitacao ? 'Solicitação' : 'Cotação'} não encontrada`);
          history.goBack();
          return;
        }
        if (foundParams?.cotacao) {
          setIdPurchase(response?.idPurchase);
          setLinkedSolicitation && setLinkedSolicitation(response?.purchase?.code);
          setLinkedQuote && setLinkedQuote(response?.code);
          setExtraValues(response?.splitExtraValues || response?.suppliers?.[0]?.quoteSuppliers?.extraValues || {});
        }

        setListRefurbish(response?.refurbishesList || (response?.refurbish ? [response?.refurbish] : []));
        const _purchaseList = response?.completeApportionmentList
          ? Object.entries(response?.completeApportionmentList || {})?.reduce((acc, [key, arrayItems]) => {
              setSplitQuoteIds(prev => ({ ...prev, [key]: arrayItems?.[0]?.idQuote }));
              acc[key] = arrayItems?.reduce(
                (accArrayItems, { purchaseItemLevels, quoteItemLevels, quoteItemSuppliers, ...item }) => {
                  if (quoteItemSuppliers?.[0]?.isUnavailable) return accArrayItems;

                  const _item = {
                    ...item,
                    [levelsColumnName]: purchaseItemLevels || quoteItemLevels || [],
                    price: quoteItemSuppliers?.[0]?.price || 0,
                    quantity: quoteItemSuppliers?.[0]?.quantity || 0
                  };

                  if (quoteItemSuppliers?.[0]?.quantity !== item.quantity) {
                    _item[levelsColumnName] = quoteItemLevels?.map(itemLevel => {
                      return {
                        ...itemLevel,
                        quantity: (itemLevel.percentage / 100) * quoteItemSuppliers?.[0]?.quantity
                      };
                    });
                  }

                  accArrayItems.push(_item);
                  return accArrayItems;
                },
                []
              );
              return acc;
            }, {})
          : {
              [response?.refurbish?.id]: foundParams?.solicitacao
                ? response?.purchaseItems
                : response?.quoteItems?.reduce((acc, { purchaseItemLevels, quoteItemLevels, ...curr }) => {
                    if (curr?.quoteItemSuppliers?.[0]?.isUnavailable) return acc;

                    const _curr = {
                      ...curr,
                      [levelsColumnName]: purchaseItemLevels || quoteItemLevels || [],
                      price: curr?.quoteItemSuppliers?.[0]?.price || 0,
                      quantity: curr?.quoteItemSuppliers?.[0]?.quantity || 0
                    };

                    if (curr?.quoteItemSuppliers?.[0]?.quantity !== curr.quantity) {
                      _curr[levelsColumnName] = curr.quoteItemLevels?.map(itemLevel => {
                        return {
                          ...itemLevel,
                          quantity: (itemLevel.percentage / 100) * curr?.quoteItemSuppliers?.[0]?.quantity
                        };
                      });
                    }

                    acc.push(_curr);
                    return acc;
                  }, []) || []
            };

        setPurchaseList(_purchaseList || {});
        // ------------------

        if (foundParams?.solicitacao && setLinkedSolicitation) setLinkedSolicitation(response?.code);

        applyValuesInForm({
          values: {
            ...response,
            quoteReferenceSplit: foundParams?.cotacao ? response?.splitId : null,
            idSupplier: foundParams.fornecedor || response?.idSupplier
          },
          childrenName: foundParams?.solicitacao ? 'purchaseItems' : 'quoteItems',
          dateName: foundParams?.solicitacao ? 'purchaseDate' : 'responseDate',
          levelsOriginName: foundParams?.solicitacao ? 'purchaseItemLevels' : 'quoteItemLevels',
          levelsDestinationName: stringsMap[model]?.levelsColumnName
        });

        if ((response?.refurbish || foundParams?.cotacao) && setAddressFormValues) {
          setAddressFormValues({
            [response?.refurbish?.id]: getObjectWithSelectedFields(
              foundParams?.cotacao ? response : response?.refurbish,
              ['zipcode', 'street', 'number', 'complement', 'state', 'city', 'neighborhood']
            )
          });
          // eslint-disable-next-line no-param-reassign
          forceAddressFormUpdate.current = true;
          preventUpdateWithRefurbishAddress.current = true;
        }
        setLoadingParams(false);
      });
    }
  }, [foundParams]);

  useEffect(() => {
    if (refurbish?.id && !preventUpdateWithRefurbishAddress.current && setAddressFormValues) {
      setAddressFormValues(prev => ({
        ...prev,
        [refurbish?.id]: getObjectWithSelectedFields(refurbish, [
          'zipcode',
          'street',
          'number',
          'complement',
          'state',
          'city',
          'neighborhood'
        ])
      }));
      setListRefurbish(prev => Array.from(new Set([...prev, refurbish])));
      setPurchaseList(prev => {
        const updatedList = { ...prev };
        updatedList[refurbish.id] = [];
        return updatedList;
      });
      // eslint-disable-next-line no-param-reassign
      forceAddressFormUpdate.current = true;
    }
  }, [refurbish]);

  useEffect(() => {
    if (purchaseFormValues?.idRefurbish) setIdRefurbish(purchaseFormValues?.idRefurbish);
  }, [purchaseFormValues]);

  useEffect(() => {
    if (idRefurbish && !id) getRefurbish({ refetchPathOptions: `/${idRefurbish}` });
  }, [idRefurbish]);

  useEffect(() => {
    if (!list?.length && !foundParams?.projeto) {
      setCanEditRefurbish(true);
      return;
    }
    setCanEditRefurbish(false);
  }, [list, foundParams]);

  useEffect(() => {
    if (!data?.id) return;
    setListRefurbish(data?.refurbishesList || (data?.refurbish ? [data?.refurbish] : []));
    setPurchaseList(
      data?.completeApportionmentList ||
        (data?.[childrenColumnName] ? { [data?.idRefurbish]: data?.[childrenColumnName] || [] } : {})
    );

    applyValuesInForm({
      values: data,
      childrenName: childrenColumnName,
      dateName: dateColumnName,
      levelsOriginName: levelsColumnName
    });
  }, [data]);

  const formatListToUpdate = (array, childrenName, cleanIds) =>
    array.map(item => ({
      ...item,
      idCompany,
      idUnit: item?.units?.id || 1,
      [dateColumnName]: item?.purchaseDate,
      ...(cleanIds && { id: undefined }),
      total: Number(item.price) * Number(item.quantity) || 0,
      [childrenName]: item?.[childrenName]?.map(level => ({
        ...level,
        ...(cleanIds && { id: undefined, idParent: undefined }),
        name: level.refurbishItem?.name,
        idRefurbishItem: level.refurbishItem?.id,
        price: item.price
      }))
    }));

  const mountPurchaseSplit = ({ extraFields, status, onlyOne }) => {
    const removeOriginal = id && !isSplit && !onlyOne ? id : null;
    const { extraValues } = extraFields;
    const [totalsByPercentage, totalSplitValue] = totals;
    const extraValuesSplit = extraValues ? distributeExtraValuesByPercentage(totalsByPercentage, extraValues) : {};
    const newInstallments =
      model === 'order' ? distributeInstallmentsByPercentage(totalsByPercentage, extraFields?.paymentInfo?.list) : {};

    const split = Object.entries(purchaseList).map(([key, value]) => {
      const removeId = removeOriginal || !id || !!newRefurbishes?.[key];
      return {
        ...purchaseFormValues,
        ...extraFields,
        observation,
        idCompany,
        ...(model === 'order' ? { paymentInfo: { list: newInstallments[key] } } : {}),
        ...refurbishesAddress?.[key],
        ...(removeOriginal ? { id: undefined } : { id: value[0]?.[idColumnName] }),
        splitId: removeOriginal || onlyOne ? null : id,
        isOldSplit: !!isSplit,
        [childrenColumnName]: formatListToUpdate(value, levelsColumnName, removeId),
        idRefurbish: key,
        totalSplitValue:
          formatNumber(totalSplitValue) -
          formatNumber(extraValues?.discount) +
          formatNumber(extraValues?.shipping) +
          formatNumber(extraValues?.taxes),
        ...extraValuesSplit?.[key],
        total: totalsByPercentage[key]?.totalSplitValue,
        createdItems: itemObjectsMap?.createdItems?.[key] || {},
        createdLevels: itemObjectsMap?.createdLevels?.[key] || {},
        deletedItems: itemObjectsMap?.deletedItems?.[key] || {},
        deletedLevels: itemObjectsMap?.deletedLevels?.[key] || {},
        updatedItems: itemObjectsMap?.updatedItems?.[key] || {},
        updatedLevels: itemObjectsMap?.updatedLevels?.[key] || {},

        idQuote: isSplit ? splitQuoteIds?.[key] : idQuote,
        idPurchase,

        [statusColumnName]: status || (isReview ? statusEnum.open : data?.[statusColumnName] || statusEnum.draft)
      };
    });

    if (onlyOne) return split[0];

    return { split, removeOriginal };
  };

  const handleSave = ({ extraFields = {}, status, extraQuery, returning } = {}) => {
    if (model === 'purchase' && !idRefurbish) {
      toast.error(`Não é possível criar uma ${baseName} sem projeto.`);
      return null;
    }
    if (!purchaseFormValues?.name) {
      toast.error('Informe o nome.');
      return null;
    }

    const onlyOne = Object.values(purchaseList).length <= 1;

    const { extraValues } = extraFields;

    const values =
      model === 'purchase'
        ? {
            ...purchaseFormValues,
            ...extraFields,
            discount: _formatNumber(extraValues?.discount),
            taxes: _formatNumber(extraValues?.taxes),
            shipping: _formatNumber(extraValues?.shipping),
            observation,
            idPurchase,
            idQuote,
            idCompany,
            [statusColumnName]: status || (isReview ? statusEnum.open : data?.[statusColumnName] || statusEnum.draft),
            [childrenColumnName]: formatListToUpdate(list, levelsColumnName, !id),
            ...itemObjectsMap
          }
        : mountPurchaseSplit({
            extraFields,
            status,
            onlyOne
          });

    /* if (id || !id) return null; */

    const saveFn = id
      ? handleUpdate({
          values,
          updatePathOptions: isSplit || !onlyOne ? `/split/${id}` : `/${id}`,
          refresh: false,
          displayToast: true,
          updateOptions: {}
        })
      : handleCreate({
          id,
          postPathOptions: !onlyOne ? `/split/` : `/`,
          values,
          refresh: false,
          displayToast: true,
          noLoading: returning
        });

    return saveFn.then(response => {
      const isArray = Array.isArray(response);
      const splitIdResponse = isArray ? response?.[0]?.splitId : null;
      const _id = response?.id || splitIdResponse;
      if (response?.error || !_id) return null;
      if (returning) return isArray ? response?.find(i => i?.isMainSplit) || response : response;

      const queryParts = [
        foundParams?.projeto && { key: 'projeto', value: idRefurbish },
        splitIdResponse && { key: 'isSplit', value: true }
      ];

      const query = buildQueryString(queryParts, extraQuery);

      history.push(`${baseUrl}/visualizar/${_id}${query}`);
      return null;
    });
  };

  const validateSubmit = ({ extraFields, callback }) => {
    let everyItemLinked = true;
    let everyItemWithAppropriation = true;
    let validAppropriations = true;

    if (!purchaseFormValues?.name) {
      toast.error('Informe um nome.');
      return false;
    }

    const purchaseError = !list?.length;
    const apportionmentError = !Object.values(purchaseList).length || !Object.values(purchaseList).some(i => i?.length);
    const hasListError = model === 'purchase' ? purchaseError : apportionmentError;

    if (hasListError) {
      toast.error(`Não é possível criar uma ${baseName} sem itens.`);
      return false;
    }

    if (model === 'order') {
      if (!purchaseFormValues?.idSupplier) {
        toast.error('Selecione um fornecedor.');
        return false;
      }

      if (!extraFields?.isPaymentResponsibleValid) {
        toast.error('Selecione uma opção de faturamento.');
        return false;
      }

      if (!extraFields?.paymentInfo?.list?.length) {
        toast.error('Selecione uma condição de pagamento.');
        return false;
      }

      if (!extraFields?.paymentTypes?.length) {
        toast.error('Selecione uma forma de pagamento.');
        return false;
      }

      if (extraFields?.paymentInfo?.list?.find(i => i.price < 0 || i.percentage < 0)) {
        toast.error('O valor deve ser maior que 0');
        return false;
      }
    }

    list?.forEach(item => {
      if (!item?.idItem && !item.externalBaseCode) {
        everyItemLinked = false;
      }
      if (!item?.[levelsColumnName]?.length) {
        everyItemWithAppropriation = false;
        return;
      }

      const totalPercentage = item?.[levelsColumnName]?.reduce((acc, cur) => acc + (Number(cur?.percentage) || 0), 0);
      const totalQuantity = item?.[levelsColumnName]?.reduce((acc, cur) => acc + (Number(cur?.quantity) || 0), 0);

      if (Math.abs(totalPercentage - 100) >= 0.01) validAppropriations = false;
      if (Math.abs(Number(item.quantity) - totalQuantity) >= 0.1) validAppropriations = false;
    });

    const hasErrorInItems = [];
    Object.entries(purchaseList || {})?.forEach(([key, value]) => {
      const validateString = validatePurchaseTableItems({
        values: { [childrenColumnName]: value },
        setInvalidItems: stringError => setInvalidPaymentItems(prev => ({ ...prev, [key]: stringError })),
        arrayToValidate: childrenColumnName
      });
      if (!validatePercentage(value)) validAppropriations = true;
      if (validateString) return;
      hasErrorInItems.push(validateString);
    });
    if (hasErrorInItems.length) {
      toast.error('Verifique os erros dos itens antes de salvar!');
      return false;
    }

    if (!validAppropriations) {
      toast.error('A soma das apropriações deve ser igual a 100%.');
      return false;
    }

    if (!everyItemLinked && !everyItemWithAppropriation && isReview) {
      setModalProps({
        show: true,
        text: `Existem insumos não vinculados com a biblioteca e não apropriados.
          Caso prossiga com a ${id ? 'edição' : 'criação'} da ${baseName}, esses itens:`,
        list: [
          'Os valores de consumo e custo desse item não serão agrupados corretamente.',
          'Não serão contabilizados em nenhum nível existente no seu Orçamento'
        ],
        callback
      });

      return false;
    }

    if ((!everyItemLinked || !everyItemWithAppropriation) && isReview) {
      setModalProps({
        show: true,
        text: !everyItemLinked
          ? `Existem insumos não vinculados à biblioteca. Caso prossiga com a ${id ? 'edição' : 'criação'}
               da ${baseName}, os valores de consumo e custo desse item não serão agrupados corretamente.`
          : `Existem insumos não apropriados. Caso prossiga com a ${id ? 'edição' : 'criação'} da ${baseName},
                esse item não será contabilizado em nenhum nível existente no seu Orçamento`,
        list: null,
        callback
      });
      return false;
    }

    if (callback) return callback();

    return true;
  };

  const getCode = () =>
    handleGet({
      refetchPathOptions: '',
      refetchOptions: { where: { idCompany }, attributes: ['code'], order: [['code', 'desc']], limit: 1 },
      generateLoading: false
    }).then(response => {
      if (response?.error) return `${codePrefix}${padLeft('0', 5)}`;

      const code = response?.[0]?.code ? Number(response[0].code.replace(codePrefix, '')) : 0;

      return `${codePrefix}${padLeft(String(code + 1), 5)}`;
    });

  // rateio - split ----------
  const { handleGet: handleGetRefurbish } = useCRUD({
    model: 'refurbish',
    immediatelyLoadData: false
  });

  const handleSetList = refurbishId => setFunc => {
    setTouched(true);
    const result = setFunc(purchaseList[refurbishId]);
    setPurchaseList(prev => ({ ...prev, [refurbishId]: result }));
  };

  const listSimpleToApportionment = (refurbishesList, libraryData) => {
    const newList = mountApportionmentItems({
      refurbishList: [...listRefurbish, ...refurbishesList],
      paymentItemsList: Object.values(purchaseList)[0],
      libraryData
    });
    return clearItemToApportionment(newList, listRefurbish?.[0]?.id, levelsColumnName);
  };

  const handleLoadRefurbishes = (ids, itemWithoutId) => {
    const listToCreate = Object.values(purchaseList)[0]?.filter(item => !item.idItem);
    const func =
      listToCreate?.length && itemWithoutId
        ? handleCreateLibraryItem({ values: { list: listToCreate }, refresh: false, postPathOptions: '/copyBulk' })
        : Promise.resolve([]);
    const getRefurbishes = handleGetRefurbish({
      refetchOptions: {
        attributes: ['id', 'name', 'zipcode', 'street', 'number', 'complement', 'state', 'city', 'neighborhood'],
        where: { id: ids }
      }
    });
    return Promise.all([getRefurbishes, func]).then(([refurbishesList, libraryData]) => {
      if (!refurbishesList.length) return toast.error('Algo de errado ocorreu.');
      setListRefurbish(prev => Array.from(new Set([...prev, ...refurbishesList])));
      setNewRefurbishes(prev => ({
        ...prev,
        ...refurbishesList.reduce((acc, item) => ({ ...acc, [item.id]: true }), {})
      }));

      setRefurbishesAddress(prev => ({
        ...prev,
        ...refurbishesList.reduce(
          (acc, item) => ({
            ...acc,
            [item.id]: getObjectWithSelectedFields(item, [
              'zipcode',
              'street',
              'number',
              'complement',
              'state',
              'city',
              'neighborhood'
            ])
          }),
          {}
        )
      }));
      const newList = listSimpleToApportionment(refurbishesList, libraryData);

      return setPurchaseList(prev => {
        if (newList && itemWithoutId) return newList;
        const updatedList = { ...prev };
        refurbishesList.forEach(item => {
          updatedList[item.id] =
            mergeItems({
              items: purchaseList,
              isNew: true,
              idColumnName,
              levelsColumnName,
              removeAppropriation: true
            }) || [];
        });
        return updatedList;
      });
    });
  };

  const addNewProject = (_newRefurbishes, itemWithoutId) => {
    handleLoadRefurbishes(
      _newRefurbishes.map(r => r?.value || r?.id),
      itemWithoutId
    );
  };

  const removeRefurbish = ({ id: idRefurbishToRemove }) => {
    setTouched(true);
    setListRefurbish(prev => prev.filter(item => item.id !== idRefurbishToRemove));
    setPurchaseList(prev => {
      const { [idRefurbishToRemove]: _, ...updatedList } = prev;
      return updatedList;
    });
    setRefurbishesAddress(prev => {
      const { [idRefurbishToRemove]: _, ...updatedList } = prev;
      return updatedList;
    });
  };

  const handleAddItem = (item, includeItemLevel) => {
    setTouched(true);
    const cleanPrice = formatNumber(item.price);
    const cleanQuantity = formatNumber(item.quantity);
    const _id = uuidV4();
    const appropriations = item?.parentList?.length
      ? item?.parentList?.map(parent => formatAppropriation({ appropriation: parent, idParent: _id, parent: item }))
      : null;

    setAddItemType(null);
    listRefurbish?.forEach(refurbishAdd => {
      setCreatedItems(prev => ({
        ...prev,
        [refurbishAdd.id]: { ...(prev?.[refurbishAdd.id] ? prev[refurbishAdd.id] : {}), [_id]: true }
      }));
    });

    return {
      ...item,
      price: cleanPrice || 0,
      quantity: cleanQuantity || 0,
      id: _id,
      ...(!item?.idItem && { idRefurbishItem: item.id }),
      percentage: 1,
      ...(includeItemLevel && { [levelsColumnName]: appropriations }),
      ...(!includeItemLevel && { parentList: null }),
      purchaseDate: new Date()
    };
  };

  const onItemClick = _type => {
    if (addItemType) return;
    setAddItemType(_type);
  };

  useEffect(() => {
    setTotals(calculateTotalPercentageSplit(purchaseList));
    setMergedList(mergeItems({ items: purchaseList, mid: true, refurbishesList: list, idColumnName }));
  }, [purchaseList]);

  const handleSubmitCatalog = ({ itemData, ...newItem }, idReference) => {
    setTouched(true);
    const _list = purchaseList?.[idReference] || [];
    if (newItem?.childId) {
      const index = _list?.findIndex(i =>
        i?.idItem ? i?.idItem === newItem?.childId : i?.idRefurbishItem === newItem?.childId
      );

      if (index > -1) {
        toast.error(`Item já existe na lista`);
        return;
      }

      setPurchaseList(prev => ({
        ...prev,
        ...Object.keys(prev).reduce((acc, key) => {
          acc[key] = [...prev[key], handleAddItem(itemData, true)];
          return acc;
        }, {}),

        ...(idReference
          ? {
              [idReference]: [
                ...(prev?.[idReference] || []),
                handleAddItem({ ...itemData, parentList: [newItem] }, true)
              ]
            }
          : {})
      }));
      setOpenAllRefurbishes(true);
      return;
    }
    const index = _list?.findIndex(
      i => (newItem?.idItem && i?.idItem === newItem?.idItem) || (newItem?.id && i?.idRefurbishItem === newItem?.id)
    );

    if (index > -1) {
      toast.error(`Item já existe na lista`);
      return;
    }
    setPurchaseList(prev => ({
      ...prev,
      ...Object.keys(prev).reduce((acc, key) => {
        acc[key] = [...prev[key], handleAddItem(newItem)];
        return acc;
      }, {}),

      ...(idReference ? { [idReference]: [...(prev?.[idReference] || []), handleAddItem(newItem, true)] } : {})
    }));
    setOpenAllRefurbishes(true);
  };

  const handleAddAll = dataToAdd => {
    setTouched(true);
    setPurchaseList(prev => {
      const updatedPrev = {};
      Object.keys(prev).forEach(key => {
        updatedPrev[key] = [...(prev[key] || []), handleAddItem(dataToAdd)];
      });
      return updatedPrev;
    });
    setAddItemType(null);
    setPressedButtonType(null);
    setOpenAllRefurbishes(true);
  };

  useEffect(() => {
    if (tabView === tabEnum.totals)
      setApportionmentTotalsList(
        mergeItems({ items: purchaseList, mid: true, refurbishesList: listRefurbish, idColumnName })
      );
  }, [tabView]);

  const appropriationProps = {
    addRefurbishModal,
    setAddRefurbishModal,
    listRefurbish,
    addItemType,
    setAddItemType,
    touched,
    setTouched,
    totals,
    setTotals,
    openAllRefurbishes,
    setOpenAllRefurbishes,
    purchaseList,
    setPurchaseList,
    handleSetList,
    invalidPaymentItems,
    setInvalidPaymentItems,
    removeRefurbishModal,
    setRemoveRefurbishModal,
    addNewProject,
    removeRefurbish,
    pressedButtonType,
    setPressedButtonType,
    handleAddAll,
    onItemClick,
    itemsOnListMap,
    setItemsOnListMap,
    handleSubmitCatalog,
    showCatalogDrawer,
    setShowCatalogDrawer,
    tabEnum,
    apportionmentTotalsList,
    tabView,
    setTabView,
    mergedList,
    setRefurbishesAddress,
    idQuote: loading || loadingParams ? true : idQuote,
    refurbishesAddress
  };
  // ----------

  return {
    id,
    data,
    loading: loading || loadingParams,
    modelGet: handleGet,
    modelCreate: handleCreate,
    modelUpdate: handleUpdate,
    idRefurbish,
    refurbish,
    forceFormUpdate,
    canEditRefurbish,
    canEditSupplier,
    purchaseFormValues,
    setPurchaseFormValues,
    handleSave,
    validateSubmit,
    itemObjectsMap,
    hasObservation,
    setHasObservation,
    observation,
    setObservation,
    shortPath,
    getCode,
    modalConfirmDelete,
    isSplit,
    ...appropriationProps
  };
};

export default usePurchase;
