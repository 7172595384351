import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListUl, faPaperclip, faPlus, faDiagramNested, faExternalLink } from '@fortawesome/pro-regular-svg-icons';
import { faFlag, faCheckCircle } from '@fortawesome/pro-solid-svg-icons';

import { Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import EditableInput from '../../../components/Input/EditableInput';
import ColorPickerDropDown from '../../../components/Dropdown/ColorPickerDropDown';
import StatusSelector from '../../../components/StatusSelector/StatusSelector';

import ColumnWrapper from '../../../components/Table/ColumnWrapper';
import TooltipIcon from '../../../components/Tooltip/TooltipIcon';
import MaskedInput from '../../../components/Input/MaskedInput';
import PerformedHoursDropdown from '../../../components/Dropdown/PerformedHoursDropdown';

import { getDateWithCustomHours, getInitials, toHHMM, validDate } from '../../helpers/helper';
import RangePicker from '../../../components/Datepicker/RangePicker';
import Avatar from '../../../components/AvatarEditor/Avatar';

import { Div, breakpoints, colors, spaces } from '../../../styles/style';
import {
  StepNameContainer,
  ColorAndNameContainer,
  IconsDiv,
  ChecklistContainer,
  TaskNameContainer,
  RefurbishNameContainer
} from './scheduleColumns.style';
import ExpandAllButton from '../../../components/Button/ExpandAllButton';
import { Paragraph, Subtitle } from '../../../components/Text/Text';
import TooltipText from '../../../components/Tooltip/TooltipText';
import Button from '../../../components/Button/Button';
import { calculateColumnDurationChange } from '../../helpers/ganttHelperFunctions';
import TaskActionDropdown from '../../../components/Dropdown/TaskActionDropdown';

const isTask = (_row, generalTask) => {
  return generalTask || _row?.idTemplateStep || _row?.idRefurbishStep;
};

const columns = ({
  isMobile,
  handleChange,
  handleAdd,
  loadData,
  priorityList,
  statusList,
  serializedStatusArray,
  serializedPrioritiesArray,
  taskStatusEnum,
  refurbishStatusEnum,
  userType,
  idReference,
  referKey,
  isApply,
  idCompany,
  printColumns = [],
  generalTask,
  isDefaultOrder,
  setCurrentData,
  lastAdd,
  handleToggleAllRow,
  workDays,
  dayoffs,
  getFormattedLinks,
  handleChangePredecessor,
  ganttInstance,
  setEditScheduleItemParams,
  userTypeEnum,
  handleDeleteRefurbishStep,
  handleDuplicateTask
}) => {
  const isTemplate = referKey === 'idTemplate';
  const isPrint = !!printColumns?.length;
  const shouldRenderMoreColumns = window.innerWidth >= breakpoints?.wide?.split('px')[0];
  const getWorkDays = row => {
    return row?.refurbish?.workingDays || (generalTask && row?.idRefurbish ? null : workDays);
  };
  const getDayoffs = row => {
    return row?.refurbish?.refurbishDayoffs || (generalTask && row?.idRefurbish ? null : dayoffs);
  };

  const isCustomer = userType === userTypeEnum?.customer?.value;

  const allColumns = [
    ...(!generalTask
      ? [
          {
            title: 'Nº',
            dataIndex: 'index',
            width: 64,
            key: 'index',
            render: (val, row) => {
              return row.isRoot ? (
                <div />
              ) : (
                <div
                  key={`number${row.id}`}
                  style={{ display: 'flex', alignItems: 'center', minHeight: spaces.space4, marginLeft: spaces.space0 }}
                >
                  {row?.index}
                </div>
              );
            },
            shouldCellUpdate: (prevRecord, nextRecord) => prevRecord.index !== nextRecord.index
          }
        ]
      : []),
    {
      title: () =>
        generalTask || isTemplate ? (
          'Tarefas'
        ) : (
          <ExpandAllButton
            handleToggleAllRow={handleToggleAllRow}
            title="Etapas/tarefas"
            tooltip="Abrir/fechar todos as etapas"
          />
        ),
      printTitle: generalTask || isTemplate ? 'Tarefas' : 'Etapas/tarefas',
      dataIndex: 'name',
      key: 'name',
      width: generalTask || isApply ? 300 : 450,
      renderMobile: true,
      sorter: true,
      render: (val, row) => {
        const { tasks, taskCount, onClick = f => f, longPressEvent = () => {} } = row;
        if (isPrint) {
          return <div style={{ paddingLeft: '8px' }}>{row?.name || '-'}</div>;
        }

        if (!isTask(row, generalTask) && !generalTask) {
          const _taskCount = tasks != null ? tasks.length : taskCount || 0;
          const isGeneral = row.id === 1;
          return (
            <StepNameContainer onClick={onClick} key={`step-container-${row.id}`}>
              <ColorAndNameContainer paddingLeft={isGeneral || isMobile ? 28 : null}>
                {!isGeneral && !isMobile ? (
                  <ColorPickerDropDown
                    color={row.color ? row.color : colors.neutral300}
                    onSelect={value =>
                      handleChange(
                        {
                          id: row.id,
                          color: value
                        },
                        false
                      )
                    }
                  />
                ) : null}
                {row?.id === 1 ? (
                  <div id="step-name">{val}</div>
                ) : (
                  <EditableInput
                    id="step-name"
                    key={`name${row.id}`}
                    value={val}
                    row={row}
                    onChange={name => handleChange({ id: row.id, name }, false)}
                    disabled={row.isDisabled || isApply || generalTask}
                    initEdit={lastAdd?.current === row.id}
                    onClick={onClick}
                    rowId={row.id}
                    width="90%"
                  />
                )}
              </ColorAndNameContainer>
              <IconsDiv>
                {!isApply ? (
                  <Tooltip placement="top" title="Adicionar tarefa">
                    <Button
                      type="outline"
                      padding={0}
                      width={spaces.space3}
                      style={{ height: spaces.space3, flexShrink: 0 }}
                      onClick={e => {
                        e.stopPropagation();
                        handleAdd(row.id, true);
                      }}
                    >
                      <FontAwesomeIcon icon={faPlus} style={{ cursor: 'pointer' }} color={colors.primary600} />
                    </Button>
                  </Tooltip>
                ) : null}

                {_taskCount != null ? (
                  <Tooltip title={_taskCount && !isTemplate ? `Selecionar todas as tarefas` : null}>
                    <Button
                      id="task-count"
                      text
                      onClick={e => {
                        e.stopPropagation();
                      }}
                    >
                      {_taskCount} tarefa{_taskCount !== 1 && 's'}
                    </Button>
                  </Tooltip>
                ) : null}
              </IconsDiv>
            </StepNameContainer>
          );
        }

        const totalItemsChecklist = row.checklist?.length;
        const totalItemsChecklistDone = row.checklist?.filter(item => item.checked).length;
        const checklistIconColor =
          totalItemsChecklist === totalItemsChecklistDone ? colors.green300 : colors.neutral400;

        const checklistIconColorHover =
          totalItemsChecklist === totalItemsChecklistDone ? colors.green300 : colors.neutral600;

        const { length: dependenciesCount } = row?.targetLinks || [];

        return (
          <TaskNameContainer
            key={`task-name-${row.id}`}
            onClick={onClick}
            showIconOnHover={row.status !== 4}
            $checklistColorHover={checklistIconColorHover}
            $showLinkedTask={row.idParent}
          >
            {isMobile ? (
              <p {...(longPressEvent(row) || {})}>{val}</p>
            ) : (
              <EditableInput
                id="task-name"
                style={{ alignSelf: 'center' }}
                key={`name${row?.id}`}
                value={val}
                row={row}
                onChange={name => handleChange({ id: row?.id, name })}
                initEdit={lastAdd?.current === row.id}
                disabled={isApply}
                rowId={row.id}
                width="60%"
              />
            )}

            {!generalTask && row?.targetLinks?.length !== 0 && (
              <Div className="linkedTask showHover" gap={spaces.space0}>
                <TooltipIcon
                  iconProps={{
                    size: 'md'
                  }}
                  text={`${dependenciesCount} dependência${dependenciesCount === 1 ? '' : 's'}`}
                  icon={faDiagramNested}
                  iconColor={colors.neutral500}
                  onClick={e => {
                    e.stopPropagation();
                    setEditScheduleItemParams({ id: row?.id, idReference, tab: '1' });
                  }}
                />
                <Paragraph color={colors.neutral500} type="small">
                  {dependenciesCount}
                </Paragraph>
              </Div>
            )}

            {row?.checklist && totalItemsChecklist !== 0 && (
              <Tooltip title="Checklist">
                <ChecklistContainer $color={checklistIconColor}>
                  <Div>
                    <FontAwesomeIcon color={colors.neutral500} icon={faListUl} />
                  </Div>
                  <Paragraph type="small" color={colors.neutral500}>
                    {totalItemsChecklistDone}/{totalItemsChecklist}
                  </Paragraph>
                </ChecklistContainer>
              </Tooltip>
            )}

            {row?.countFile > 0 ? (
              <TooltipIcon
                iconProps={{
                  size: 'md'
                }}
                text="Anexos"
                icon={faPaperclip}
                iconColor={colors.neutral500}
                onClick={e => {
                  e.stopPropagation();
                  if (!isApply) {
                    setCurrentData(row);
                  }
                }}
              />
            ) : null}
            {!isApply && (
              <Tooltip
                zIndex={1500}
                placement="top"
                title={`${row?.status === taskStatusEnum?.finished?.id ? 'Reabrir tarefa' : 'Concluir tarefa'}`}
              >
                <Button
                  className="checkCircle showHover"
                  type="outline"
                  padding={0}
                  width={spaces.space3}
                  style={{ height: spaces.space3, flexShrink: 0 }}
                  onClick={e => {
                    e.stopPropagation();
                    return !isApply
                      ? handleChange(
                          {
                            id: row.id,
                            status:
                              row.status !== taskStatusEnum.finished.id
                                ? taskStatusEnum.finished.id
                                : taskStatusEnum.execution.id
                          },
                          true,
                          row.status !== taskStatusEnum.finished.id
                        )
                      : null;
                  }}
                >
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    style={{ cursor: 'pointer' }}
                    color={row.status === taskStatusEnum?.finished?.id ? colors.green500 : colors.neutral500}
                  />
                </Button>
              </Tooltip>
            )}
          </TaskNameContainer>
        );
      },
      shouldCellUpdate: () => true
    },
    ...(generalTask
      ? [
          {
            title: 'Projeto/Oportunidade',
            dataIndex: ['refurbish', 'name'],
            key: 'idRefurbish',
            width: 160,
            sorter: true,
            renderMobile: true,
            render: (val, row) => {
              const { id, refurbish } = row;
              if (isPrint) {
                return row?.refurbish?.name;
              }

              return (
                <RefurbishNameContainer>
                  <ColumnWrapper
                    disabled
                    id="task-refurbish-name"
                    value={refurbish}
                    model="refurbish"
                    onSelect={_idRefurbish => {
                      return handleChange({ id, idRefurbish: _idRefurbish });
                    }}
                    options={{
                      order: [['name']]
                    }}
                    cleanValue={null}
                    canCreate={false}
                  />
                  {refurbish && (
                    <Link
                      to={`/${userType}/${
                        refurbish?.idStatus === refurbishStatusEnum.execution.id ? 'projetos' : 'oportunidades'
                      }/perfil/${refurbish.id}/tarefas`}
                      style={{ margin: spaces.space1 }}
                    >
                      <Button
                        className="goToRefurbishButton"
                        type="outline"
                        padding={0}
                        width={spaces.space3}
                        style={{ height: spaces.space3, flexShrink: 0 }}
                      >
                        <FontAwesomeIcon
                          icon={faExternalLink}
                          style={{ cursor: 'pointer' }}
                          color={colors.primary600}
                          className={`goToRefurbishButton goToRefurbish-${refurbish.id}`}
                        />
                      </Button>
                    </Link>
                  )}
                </RefurbishNameContainer>
              );
            },
            shouldCellUpdate: false
          }
        ]
      : []),
    ...(generalTask
      ? [
          {
            title: 'Etapa',
            dataIndex: ['refurbishStep', 'name'],
            key: 'idRefurbishStep',
            width: 140,
            sorter: true,
            renderMobile: true,
            render: (val, row) => {
              const { id, refurbishStep } = row;

              if (isPrint) {
                return refurbishStep?.name || '-';
              }
              return (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <ColumnWrapper
                    disabled
                    id="task-refurbish-step"
                    value={refurbishStep}
                    model={row?.refurbish?.id && 'refurbishStep'}
                    options={{
                      where: { or: [{ idRefurbish: row?.refurbish?.id }, { id: 1 }] },
                      order: [['name']]
                    }}
                    onSelect={idRefer => handleChange({ id, idRefurbishStep: idRefer })}
                    cleanValue={1}
                    canCreate={false}
                  />
                </div>
              );
            },
            shouldCellUpdate: false
          }
        ]
      : []),
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 160,
      renderMobile: true,
      sorter: !isPrint,
      render: (val, row) => {
        const { id } = row;
        const { longPressEvent = () => {} } = row;
        const value = serializedStatusArray[val];
        const statusSerialized = serializedStatusArray[val] || val;

        if (isPrint) return <div>{statusSerialized?.label}</div>;
        return isTask(row, generalTask) ? (
          <>
            {isMobile ? (
              <div style={{ flex: 1 }} {...(longPressEvent(row) || {})}>
                {row?.status ? <StatusSelector inlineText status={value} /> : '-'}
              </div>
            ) : (
              <ColumnWrapper
                value={value}
                _key="value"
                onSelect={status =>
                  handleChange(
                    { id, status },
                    true,
                    status === taskStatusEnum.finished.id,
                    status === taskStatusEnum.cancelled.id
                  )
                }
                loadList={statusList}
                canCreate={false}
                showClean={false}
                IconSelector={({ item }) => <StatusSelector status={item} />}
                disabled={isApply}
                align="left"
                id={`status-${id}`}
              >
                {row?.status ? <StatusSelector inlineText status={value} /> : '-'}
              </ColumnWrapper>
            )}
          </>
        ) : null;
      },
      shouldCellUpdate: (prevRecord, nextRecord) => prevRecord.status !== nextRecord.status || !isDefaultOrder
    },
    {
      title: 'Duração',
      dataIndex: 'duration',
      key: 'duration',
      width: 120,
      renderMobile: true,
      sorter: true,
      render: (val, row) => {
        let _val = val;
        if (!val && row.startDate && row.endDate) {
          _val = generalTask
            ? '-'
            : ganttInstance?.calculateDuration({
                start_date: ganttInstance.date.convert_to_utc(getDateWithCustomHours(row.startDate, { hours: 3 })),
                end_date: ganttInstance.date.convert_to_utc(getDateWithCustomHours(row.endDate, { hours: 15 }))
              });
        }
        return (
          <EditableInput
            key={`code${row.id}`}
            noWrap
            value={_val}
            row={row}
            width="100%"
            disabled={generalTask || (!isTask(row, generalTask) && row.taskBasedDate)}
            onChange={value =>
              handleChange(
                calculateColumnDurationChange(ganttInstance, isTemplate, row, {
                  startDateField: 'startDate',
                  endDateField: 'endDate'
                })(value),
                !!isTask(row, generalTask)
              )
            }
            rowId={row.id}
            unit="dias"
          />
        );
      },
      shouldCellUpdate: (prevRecord, nextRecord) =>
        prevRecord.duration !== nextRecord.duration ||
        prevRecord.startDate !== nextRecord.startDate ||
        prevRecord.endDate !== nextRecord.endDate
    },
    ...(isTemplate
      ? []
      : [
          {
            title: 'Inicio',
            dataIndex: 'startDate',
            key: 'startDate',
            width: 80,
            renderMobile: true,
            sorter: true,
            onCell: () => ({
              colSpan: 2
            }),
            render: (val, row) => {
              const { longPressEvent = () => {} } = row;
              const { id, onClick = f => f } = row;

              if (isPrint) {
                return (
                  <div
                    style={{
                      marginLeft: row.startDate ? '0' : '60px',
                      color: row.isDelayed && 'red'
                    }}
                    {...(longPressEvent(row) || {})}
                  >
                    {`${validDate(row.startDate)} - ${validDate(row.endDate)}`}
                  </div>
                );
              }
              return id !== 1 ? (
                <div
                  key={`endDate-${row.id}`}
                  role="presentation"
                  onClick={onClick}
                  style={{ alignSelf: 'center', width: '100%' }}
                  {...(longPressEvent(row) || {})}
                >
                  <RangePicker
                    id="calendar"
                    taskCustomCalendar
                    size="small"
                    format="DD MMM"
                    value={[
                      row.startDate ? getDateWithCustomHours(row.startDate, { hours: 3 }) : row.startDate,
                      row.endDate ? getDateWithCustomHours(row.endDate, { hours: 15 }) : row.endDate
                    ]}
                    onChange={value => {
                      const datesObj = {
                        start_date: value.start
                          ? ganttInstance.date.date_part(getDateWithCustomHours(value.start, { hours: 3 }))
                          : value.start,
                        end_date: value.end ? getDateWithCustomHours(value.end, { hours: 15 }) : value.end,
                        unscheduled: !value.start || !value.end
                      };

                      handleChange(
                        {
                          id,
                          ...datesObj,
                          taskBasedDate: value.taskBasedDate,
                          from: 'List'
                        },
                        !!isTask(row, generalTask)
                      );
                    }}
                    disabled={isMobile || isApply || generalTask}
                    isDelayed={row.isDelayed}
                    duration={row.duration}
                    buttonProps={{ text: true }}
                    isParent={!isTask(row, generalTask)}
                    taskBasedDate={row.taskBasedDate}
                    workDays={getWorkDays(row)}
                    dayoffs={getDayoffs(row)}
                    externalFunctions={{
                      calculateEndDate: ganttInstance?.calculateEndDate,
                      convertToUtc: ganttInstance?.date?.convert_to_utc
                    }}
                  />
                </div>
              ) : null;
            },
            shouldCellUpdate: (prevRecord, nextRecord) =>
              prevRecord.startDate !== nextRecord.startDate ||
              prevRecord.endDate !== nextRecord.endDate ||
              prevRecord.isDelayed !== nextRecord.isDelayed
          },
          {
            title: 'Fim',
            dataIndex: 'endDate',
            key: 'endDate',
            width: 80,
            renderMobile: true,
            sorter: true,
            onCell: () => ({
              colSpan: 0
            }),
            shouldCellUpdate: (prevRecord, nextRecord) =>
              prevRecord.startDate !== nextRecord.startDate ||
              prevRecord.endDate !== nextRecord.endDate ||
              prevRecord.isDelayed !== nextRecord.isDelayed
          }
        ]),
    ...(shouldRenderMoreColumns
      ? [
          {
            title: 'Horas estimadas',
            dataIndex: 'estimativeDuration',
            key: 'estimativeDuration',
            width: 120,
            render: (val, row) => {
              if (isPrint || isApply) return <div>{`${toHHMM(row.estimativeDuration) || ''}`}</div>;

              return (
                <MaskedInput
                  id="time-extimated"
                  readOnly={!isTask(row, generalTask)}
                  type="tel"
                  $noBorder
                  value={row.estimativeDuration}
                  onBlur={value => handleChange({ id: row.id, estimativeDuration: value })}
                  style={{ maxWidth: '90px' }}
                />
              );
            },
            shouldCellUpdate: (prevRecord, nextRecord) =>
              prevRecord.estimativeDuration !== nextRecord.estimativeDuration
          }
        ]
      : []),
    ...(shouldRenderMoreColumns
      ? [
          {
            title: 'Horas realizadas',
            dataIndex: 'estimativeDuration',
            key: 'estimativeDuration',
            width: 120,
            render: (val, row) => {
              const isTaskRow = isTask(row, generalTask);

              if (isPrint || isApply || !isTaskRow)
                return <div>{`${toHHMM(isTaskRow ? row.totalPerformedHours : row.realDuration) || ''}`}</div>;
              return (
                <PerformedHoursDropdown
                  totalHours={row.totalPerformedHours}
                  isApply={isApply}
                  idTask={row.id}
                  afterSubmit={() => loadData({})}
                />
              );
            },
            shouldCellUpdate: (prevRecord, nextRecord) => JSON.stringify(prevRecord) !== JSON.stringify(nextRecord)
          }
        ]
      : []),
    {
      title: 'Responsável',
      dataIndex: ['user', 'name'],
      key: 'idResponsible',
      width: 120,
      sorter: !isPrint,
      render: (val, row) => {
        if (isPrint) {
          return <div>{row?.user?.name || '-'}</div>;
        }
        const { id, user } = row;
        return isTask(row, generalTask) ? (
          <ColumnWrapper
            id="task-responsible"
            model="user"
            options={{
              where: {
                isActive: true,
                idCompany,
                userType: [userTypeEnum?.provider?.value, userTypeEnum?.guest?.value]
              },
              order: [['name']]
            }}
            value={user}
            onSelect={idResponsible => handleChange({ id, idResponsible })}
            cleanValue={null}
            canCreate={false}
            IconSelector={({ item }) => (
              <Avatar size="small" src={item.avatarFullpath}>
                {getInitials(item.name)}
              </Avatar>
            )}
            disabled={isApply}
          >
            {user && (
              <Avatar size="small" src={user?.avatarFullpath}>
                {getInitials(user?.name)}
              </Avatar>
            )}
          </ColumnWrapper>
        ) : null;
      },
      shouldCellUpdate: (prevRecord, nextRecord) =>
        prevRecord.idResponsible !== nextRecord.idResponsible || !isDefaultOrder
    },
    {
      title: 'Prioridade',
      dataIndex: 'priority',
      key: 'priority',
      width: 100,
      renderMobile: true,
      sorter: !isPrint,
      render: (val, row) => {
        const { id } = row;
        const { longPressEvent = () => {} } = row;
        const value = serializedPrioritiesArray[val];

        if (isPrint) return <div>{value?.label || '-'}</div>;

        return isTask(row, generalTask) ? (
          <>
            {isMobile ? (
              <div style={{ flex: 1 }} {...(longPressEvent(row) || {})}>
                {row?.priority ? <StatusSelector text={value?.label} icon={faFlag} status={value} /> : '-'}
              </div>
            ) : (
              <ColumnWrapper
                value={value}
                _key="value"
                onSelect={priority => handleChange({ id, priority })}
                loadList={priorityList}
                cleanValue={null}
                canCreate={false}
                showClean={false}
                IconSelector={({ item }) => <StatusSelector icon={faFlag} status={item} />}
                disabled={isApply}
                align="left"
              >
                {row?.priority ? <StatusSelector text={value?.label} icon={faFlag} status={value} /> : '-'}
              </ColumnWrapper>
            )}
          </>
        ) : null;
      },
      shouldCellUpdate: (prevRecord, nextRecord) => prevRecord.priority !== nextRecord.priority || !isDefaultOrder
    },
    ...(!generalTask
      ? [
          {
            title: (
              <TooltipText
                tooltipText={
                  <div>
                    Digite a <b>numeração</b> da predecessora + <b>sigla do tipo de dependência</b> + intervalo de dias
                    para criar a dependência. Separe por vírgulas para criar múltiplas dependências.
                    <br />
                    <br />
                    Ex: 1.1FF+2, 1.2FI+3
                  </div>
                }
                text="Predecessoras"
              />
            ),
            dataIndex: 'targetLinks',
            width: 140,
            key: 'targetLinks',
            render: (val, row) => {
              if (row.isRoot) return null;

              const formattedLinks = getFormattedLinks({ value: val });

              if (isPrint) {
                return (
                  <Subtitle color={colors.neutral600} className="custom-content-pdf">
                    {formattedLinks}
                  </Subtitle>
                );
              }

              return !isTask(row, generalTask) ? null : (
                <Tooltip
                  title={
                    formattedLinks ? (
                      <div>
                        {formattedLinks.split(',').map(item => (
                          <div key={item}>{item}</div>
                        ))}
                      </div>
                    ) : null
                  }
                >
                  <EditableInput
                    key={`predecessor${row.id}`}
                    noWrap
                    value={formattedLinks}
                    disabled={row?.isRoot}
                    row={row}
                    width="100%"
                    style={{ wordBreak: 'break-all', whiteSpace: 'normal' }}
                    onClick={e => e.stopPropagation()}
                    onChange={value => handleChangePredecessor({ value, idTargetTask: row?.id })}
                    rowId={row.id}
                  />
                </Tooltip>
              );
            }
          }
        ]
      : []),
    {
      title: 'Ação',
      dataIndex: 'action',
      width: 70,
      renderMobile: true,
      key: 'childAction',
      align: 'center',
      render: (val, row) => {
        if (isPrint || row.id === 1) return <div />;

        const isTaskRow = isTask(row, generalTask);

        if (!isApply) {
          return (
            <Div align="center" justify="center" $fullWidth>
              <TaskActionDropdown
                task={row}
                generalTask={generalTask}
                onTaskClick={() => {
                  setEditScheduleItemParams(prev => ({
                    ...prev,
                    id: row.id,
                    idRefurbish: row.idRefurbish
                  }));
                }}
                isParent={!isTaskRow}
                ganttInstance={ganttInstance}
                handleDeleteRefurbishStep={handleDeleteRefurbishStep}
                handleDuplicateTask={handleDuplicateTask}
                onLoadData={loadData}
                disabled={isCustomer}
                isGantt={false}
              />
            </Div>
          );
        }

        return null;
      },
      shouldCellUpdate: (prevRecord, nextRecord) => JSON.stringify(prevRecord) !== JSON.stringify(nextRecord)
    }
  ];

  if (isPrint) {
    return allColumns.filter(c => printColumns.includes(c.key) && !c.onlyMobile && c.dataIndex);
  }

  return isMobile ? allColumns.filter(c => c.renderMobile) : allColumns.filter(c => !c.onlyMobile);
};

export { columns };
