import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'antd';
import { BadgeText } from '../../Text/Text';
import Button from '../../Button/Button';
import DropdownV2 from '../../Dropdown/DropdownV2';
import { Div, colors, spaces, fonts } from '../../../styles/style';
import useViewport from '../../../_Hooks/useViewport';

const DrawerHeaderComponents = ({ components }) => {
  const { isMobile } = useViewport(window.innerWidth);
  const _isMobile = isMobile();

  const renderButton = ({ icon, onClick, isPressed = false, component }) => {
    if (component) return component;

    return (
      <Button
        type="iconNeutral"
        justify="center"
        pressed={isPressed}
        width={spaces.space4}
        height={spaces.space4}
        onClick={onClick}
        style={{ position: 'relative' }}
      >
        <FontAwesomeIcon color={_isMobile ? colors.neutral500 : undefined} icon={icon} />
      </Button>
    );
  };

  const withBadge = (button, { badgeCount, title }) => {
    return (
      <BadgeText
        key={title}
        background={colors.neutral600}
        $textColor={colors.white}
        $fontSize={fonts.sizeXs}
        $padding={badgeCount > 9 ? `0px ${spaces.space0}` : `0px`}
        $borderRadius={spaces.space1}
        size="small"
        offset={[-5, 5]}
        count={badgeCount || 0}
      >
        {button}
      </BadgeText>
    );
  };

  const renderMobileComponents = () => {
    return components
      .filter(component => (component.onlyMobile || component.showOnMobile) && component.shouldDisplayWhen !== false)
      .map(component => {
        const { menu, isOpen, onClick } = component;
        const button = renderButton(component);
        const wrappedButton = component?.hasBadge ? withBadge(button, component) : button;

        if (menu) {
          return (
            <DropdownV2
              open={isOpen}
              slim
              trigger={['click']}
              menu={menu()}
              padding={0}
              showIcon={false}
              onOpenChange={onClick}
            >
              {wrappedButton}
            </DropdownV2>
          );
        }

        return wrappedButton;
      });
  };

  const renderDesktopComponents = () => {
    return components
      .filter(component => !component.onlyMobile && component.shouldDisplayWhen !== false)
      .map(component => {
        const { title, menu, isOpen, onClick, component: customComponent } = component;

        const button = renderButton({ ...component, component: customComponent });
        const wrappedButton = component?.hasBadge ? withBadge(button, component) : button;

        const tooltipButton = (
          <Tooltip key={title} placement="bottom" title={title}>
            {wrappedButton}
          </Tooltip>
        );

        if (menu) {
          return (
            <>
              {tooltipButton}
              <DropdownV2
                open={isOpen}
                slim
                trigger={['click']}
                menu={menu}
                padding={0}
                showIcon={false}
                onOpenChange={onClick}
              />
            </>
          );
        }

        return tooltipButton;
      });
  };

  return <Div gap={spaces.space0}>{_isMobile ? renderMobileComponents() : renderDesktopComponents()}</Div>;
};

DrawerHeaderComponents.propTypes = {
  components: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.oneOfType([PropTypes.instanceOf(FontAwesomeIcon), PropTypes.any]),
      title: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
      hasBadge: PropTypes.bool,
      badgeCount: PropTypes.number,
      onlyMobile: PropTypes.bool,
      showOnMobile: PropTypes.bool,
      isPressed: PropTypes.bool,
      menu: PropTypes.node,
      isOpen: PropTypes.bool,
      component: PropTypes.node,
      shouldDisplayWhen: PropTypes.bool
    })
  ).isRequired
};

export default DrawerHeaderComponents;
