import React from 'react';
import { Tooltip } from 'antd';
import { faBookmark, faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLayerGroup, faBoxOpen, faScrewdriverWrench } from '@fortawesome/pro-solid-svg-icons';
import formatCurrency from '../../helpers/formatCurrency';
import EditableInput from '../../../components/Input/EditableInput';
import formatNumber from '../../helpers/formatNumber';
import formatBdi from '../../helpers/formatBdi';
import { Div, colors, fonts, spaces } from '../../../styles/style';
import Button from '../../../components/Button/Button';
import { replaceDecimalNumber } from '../../helpers/helper';
import Select from '../../../components/Select/Select';
import { Paragraph } from '../../../components/Text/Text';

const columns = ({
  refurbishItemType = {},
  isCustomer,
  handleChange = f => f,
  onRemoveClick = f => f,
  isMobile,
  readOnly,
  unitList,
  hasPermission,
  forceCellUpdate,
  loading,
  idReference,
  isEdit,
  columnsToShow,
  parentData,
  isLinked,
  isLibrary,
  isSimple
}) => {
  const _disabledColumn = isCustomer || readOnly;

  const allColumns = [
    {
      title: 'Item',
      dataIndex: 'name',
      renderMobile: true,
      onlyMobile: true,
      mobileWidth: '100%',
      render: (val, row = {}) => {
        let icon;
        if (row.type === refurbishItemType.labor) {
          icon = faScrewdriverWrench;
        } else if (row.type === refurbishItemType.product) {
          icon = faBoxOpen;
        } else if (row.type === refurbishItemType.composition) {
          icon = faLayerGroup;
        }

        return (
          <Div>
            <Div
              width={spaces.space2}
              height={spaces.space2}
              margin={`0 ${spaces.space1} 0 0`}
              align="center"
              justify="center"
            >
              <FontAwesomeIcon icon={icon} size="sm" style={{ color: colors.neutral300 }} />
            </Div>
            <Paragraph $ellipsisClamp={2}>{row?.name}</Paragraph>
          </Div>
        );
      },
      shouldCellUpdate: (prevRecord, nextRecord) => prevRecord.status !== nextRecord.status
    },
    {
      title: 'Tipo',
      dataIndex: 'image',
      key: 'image',
      width: isSimple ? null : 48,
      alwaysShow: true,
      showInSimpleView: true,
      render: (val, row) => {
        let icon;
        let title;
        if (row.type === refurbishItemType.labor) {
          icon = faScrewdriverWrench;
          title = 'Mão de obra';
        } else if (row.type === refurbishItemType.product) {
          icon = faBoxOpen;
          title = 'Material';
        } else if (row.type === refurbishItemType.composition) {
          icon = faLayerGroup;
        }

        return isSimple ? (
          <Div>
            <Paragraph>{title}</Paragraph>
          </Div>
        ) : (
          <Div width={spaces.space4} height={spaces.space4} align="center" justify="center">
            <FontAwesomeIcon icon={icon} size="xl" style={{ color: colors.neutral300 }} />
          </Div>
        );
      }
    },
    {
      title: 'Código',
      dataIndex: 'code',
      key: 'code',
      renderMobile: true,
      width: 80,
      render: (val, row) => {
        if (isMobile) {
          return <Paragraph>{val}</Paragraph>;
        }
        return (
          <EditableInput
            id="composition-item-code"
            key={`code${val}`}
            noWrap
            value={val}
            row={row}
            onChange={handleChange(row, 'code')}
            disabled={_disabledColumn || row.isDisabled || isLinked || isLibrary || (row.idItem && !isLibrary)}
            readOnly
            rowId={row.id}
          />
        );
      },
      shouldCellUpdate: false
    },
    {
      title: 'Descrição',
      dataIndex: 'name',
      key: 'name',
      alwaysShow: true,
      render: (val, row) => {
        const { onClick = f => f } = row;

        return (
          <Div gap={spaces.space1}>
            <EditableInput
              id="composition-item-name"
              key={`name${row.id}`}
              value={val}
              row={row}
              onChange={handleChange(row, 'name')}
              disabled={_disabledColumn || row.isDisabled || isLinked || (row.idItem && !isLibrary)}
              readOnly
              withEditButton
              onClick={onClick}
              rowId={row.id}
            />
            {row.item && !isCustomer && (
              <Tooltip title={row.item?.idCompany ? 'Vinculado com a biblioteca' : row.item?.source}>
                <FontAwesomeIcon className="linked-icon" icon={faBookmark} size="lg" color={colors.neutral500} />
              </Tooltip>
            )}
          </Div>
        );
      },
      shouldCellUpdate: (prevRecord, nextRecord) =>
        prevRecord?.name !== nextRecord?.name ||
        prevRecord?.link !== nextRecord?.link ||
        prevRecord?.idItem !== nextRecord?.idItem
    },
    {
      title: 'Un.',
      dataIndex: 'idUnit',
      key: 'units',
      renderMobile: true,
      width: 80,
      render: (val, row) => {
        if (isMobile) {
          return <Paragraph>{val}</Paragraph>;
        }
        return (
          <Select
            $light
            name="Unidade"
            allowCreate={hasPermission}
            allowClear
            options={unitList}
            id={`unit${val}`}
            model="unit"
            onChange={idUnit => {
              handleChange(row, 'idUnit')(idUnit || 1);
            }}
            value={val}
            disabled={_disabledColumn || isLinked || (row.idItem && !isLibrary)}
            readOnly
            onClick={e => e.stopPropagation()}
            dropdownMatchSelectWidth={false}
          />
        );
      },
      shouldCellUpdate: (prevRecord, nextRecord) =>
        prevRecord.idUnit !== nextRecord.idUnit || forceCellUpdate === 'unit'
    },
    {
      title: 'Qtd.',
      dataIndex: 'quantity',
      key: 'quantity',
      renderMobile: true,
      width: 80,
      render: (val, row) => {
        let decimalCount;
        if (val) {
          decimalCount = replaceDecimalNumber(val);
        }

        if (isMobile) {
          return <Paragraph>{formatCurrency(val, { decimalCount })}</Paragraph>;
        }
        return row.type === refurbishItemType.parent ? null : (
          <EditableInput
            id="composition-item-quantity"
            key={`qtd${row.id}`}
            noWrap
            value={formatCurrency(val, { decimalCount })}
            row={row}
            onChange={value => handleChange(row, 'quantity')(formatNumber(value))}
            disabled={_disabledColumn || row.isDisabled}
            readOnly
            rowId={row.id}
          />
        );
      },
      shouldCellUpdate: (prevRecord, nextRecord) => prevRecord.quantity !== nextRecord.quantity
    },
    {
      title: 'Custo un.',
      dataIndex: 'price',
      key: 'price',
      renderMobile: true,
      width: isSimple ? null : 104,
      showInSimpleView: true,
      render: (val, row) => {
        if (row.type === refurbishItemType.parent) {
          return null;
        }

        if (isMobile) {
          return <Paragraph>{formatCurrency(val, { currencySymbol: 'R$' })}</Paragraph>;
        }

        return (
          <EditableInput
            id="composition-item-price"
            key={`price${row.id}`}
            type="currency"
            noWrap
            placeholder="R$ 0,00"
            value={formatCurrency(val, { currencySymbol: 'R$' })}
            row={row}
            forceShow
            onChange={value => handleChange(row, 'price')(formatNumber(value))}
            disabled={_disabledColumn || row.isDisabled || row.type === refurbishItemType?.composition}
            readOnly
            rowId={row.id}
          />
        );
      },
      shouldCellUpdate: (prevRecord, nextRecord) => prevRecord.price !== nextRecord.price
    },
    ...(idReference || (isEdit && !isLibrary)
      ? [
          {
            title: 'BDI',
            dataIndex: 'bdi',
            key: 'bdi',
            type: 'currency',
            width: isSimple ? null : 96,
            showInSimpleView: true,
            render: (val, row) => {
              const bdiFieldMap = {
                [refurbishItemType?.product]: val || parentData?.productBdi,
                [refurbishItemType?.labor]: val || parentData?.laborBdi,
                [refurbishItemType?.composition]: row?.calculatedBdi || val
              };
              const bdi = bdiFieldMap[row.type];

              return (
                <EditableInput
                  key={`bdi${row.id}`}
                  noWrap
                  value={bdi ? `${formatCurrency(bdi)}%` : null}
                  row={row}
                  onChange={value => handleChange(row, 'bdi')(formatBdi(value))}
                  disabled={
                    _disabledColumn || row.isDisabled || (!row.idItem && !row.externalBaseCode && !isEdit && !isSimple)
                  }
                  rowId={row.id}
                />
              );
            },
            shouldCellUpdate: (prevRecord, nextRecord) => prevRecord.bdi !== nextRecord.bdi
          }
        ]
      : []),
    {
      title: isSimple ? 'Preço' : 'Preço total',
      dataIndex: idReference ? 'totalBdi' : 'total',
      width: isSimple ? null : 104,
      renderMobile: true,
      showInSimpleView: true,
      key: 'totalBdi',
      render: (val, row) => {
        if (isMobile) {
          return <Paragraph>{formatCurrency(val, { currencySymbol: 'R$' })}</Paragraph>;
        }
        return (
          <div id="composition-item-total-price" key={`totalBdi${row.id}`}>
            {formatCurrency(val, { currencySymbol: 'R$' })}
          </div>
        );
      },
      shouldCellUpdate: (prevRecord, nextRecord) =>
        prevRecord.totalBdi !== nextRecord.totalBdi || prevRecord.total !== nextRecord.total
    },
    ...(!_disabledColumn && !isLinked
      ? [
          {
            title: 'Ações',
            key: 'action',
            width: 56,
            renderMobile: false,
            alwaysShow: true,
            render: (_, row) => {
              return (
                <Div $fullWidth justify="center">
                  <Button
                    $justifyContent="center"
                    type="text"
                    onClick={e => {
                      e.stopPropagation();
                      return onRemoveClick(row);
                    }}
                    loading={loading}
                    tooltipText="Remover"
                    icon={<FontAwesomeIcon icon={faTrashCan} size="lg" style={{ marginRight: 0 }} />}
                  />
                </Div>
              );
            },
            shouldCellUpdate: (prevRecord, nextRecord) =>
              prevRecord.id !== nextRecord.id || forceCellUpdate === 'actions'
          }
        ]
      : [])
  ];

  let renderColumns = allColumns;

  const getFilterCondition = column => {
    if (columnsToShow || isCustomer) {
      return (
        (columnsToShow?.[column.key] || column.alwaysShow) &&
        !column.onlyMobile &&
        (isSimple ? column.showInSimpleView : true)
      );
    }
    return !column.onlyMobile && (isSimple ? column.showInSimpleView : true);
  };

  renderColumns = allColumns.filter(getFilterCondition);

  return isMobile
    ? allColumns.filter(c => c.renderMobile && (columnsToShow ? columnsToShow?.[c.key] : true))
    : renderColumns;
};

const bdiValuesColumns = ({ isMobile, hideProductService, isSimple }) => {
  const formatToBdi = (row, val) => {
    if (row.isBdi) {
      return `${formatCurrency(val)}%`;
    }
    if (!val) {
      return null;
    }
    return formatCurrency(val, { currencySymbol: 'R$' });
  };

  const allBdiValuesColumns = [
    {
      title: '',
      dataIndex: 'rowName',
      width: 72,
      key: 'rowName',
      render: (val, row) => <div key={`rowName${row.id}`}>{row.rowName}</div>,
      shouldCellUpdate: (prevRecord, nextRecord) => prevRecord.totalProduct !== nextRecord.totalProduct
    },
    ...(!hideProductService && !isSimple
      ? [
          {
            title: 'Produtos',
            dataIndex: 'totalProduct',
            width: 144,
            align: 'right',
            key: 'totalProduct',
            render: (val, row) => <Paragraph key={`totalProduct${row.id}`}>{formatToBdi(row, val)}</Paragraph>,
            shouldCellUpdate: (prevRecord, nextRecord) => prevRecord.totalProduct !== nextRecord.totalProduct
          },
          {
            title: 'Serviços',
            dataIndex: 'totalLabor',
            width: 144,
            align: 'right',
            key: 'totalLabor',
            render: (val, row) => <Paragraph key={`totalLabor${row.id}`}>{formatToBdi(row, val)}</Paragraph>,
            shouldCellUpdate: (prevRecord, nextRecord) => prevRecord.totalLabor !== nextRecord.totalLabor
          }
        ]
      : []),
    {
      title: 'Total',
      dataIndex: 'totalBdi',
      width: 144,
      align: 'right',
      key: 'totalBdi',
      render: (val, row) => (
        <Paragraph weight={fonts.weight600} key={`totalBdi${row.id}`}>
          {formatToBdi(row, val)}
        </Paragraph>
      ),
      shouldCellUpdate: (prevRecord, nextRecord) => prevRecord.totalBdi !== nextRecord.totalBdi
    }
  ];
  const _allbdiValuesColumns = allBdiValuesColumns;

  return isMobile ? _allbdiValuesColumns.filter(c => c.renderMobile) : _allbdiValuesColumns.filter(c => !c.onlyMobile);
};

export { columns, bdiValuesColumns };
