import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Menu } from 'antd';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ConfirmModal from '../Modal/ConfirmModal';
import Dropdown from './DropdownV2';
import CopyLinkButton from '../Button/CopyLinkButton';
import useCRUD from '../../_Hooks/useCRUD';
import DropDownButton from '../Button/DropDownButton';
import { DropdownContainer, StyledMenu } from './DropdownV2.styled';
import { allDropdown } from '../../lib/mapping/Dropdown/allDropdown';
import ShareWithSupplierModal from '../Modal/ShareWithSupplierModal';
import useUrlParams from '../../_Hooks/useUrlParams';
import Button from '../Button/Button';
import { hasPermission } from '../../routes/Common/PrivateRoute';
import OrderModals from '../../_Pages/Purchases/Order/OrderModals';
import usePurchase from '../../_Hooks/usePurchase';
import { spaces } from '../../styles/style';
import useViewport from '../../_Hooks/useViewport';

const PurchaseActionDropdown = ({ data, afterSubmit = f => f, model, children, handlePrint }) => {
  const { purchaseOrderStatus, purchaseSolicitationStatus, paymentOrderType } = useSelector(state => state.setup.enums);
  const { user = null } = useSelector(state => state.authReducer) || {};
  const { isMobile } = useViewport(window.innerWidth);

  const hasUserPaymentPermission = hasPermission(user, [
    'payment',
    'projectSupplier',
    'projectOwnBusiness',
    'financialSupplier',
    'financialOwnBusiness'
  ]);
  const history = useHistory();
  const [showModal, setShowModal] = useState();
  const [showShareModal, setShowShareModal] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [openPurchaseModal, setOpenPurchaseModal] = useState({ open: false, idOrder: null });
  const [openFinanceDrawer, setOpenFinanceDrawer] = useState({ open: false });

  const { handleCreate, handleUpdate, handleDelete } = useCRUD({
    model,
    immediatelyLoadData: false
  });

  const { shortPath, modalConfirmDelete } = usePurchase({ model });

  const { foundParams, clearParams } = useUrlParams({
    urlParams: ['openShareModal']
  });

  useEffect(() => {
    if (!foundParams?.openShareModal) return;
    setShowShareModal(true);
    clearParams('openShareModal');
  }, [foundParams]);

  const actions = {
    post: handleCreate,
    put: handleUpdate,
    delete: handleDelete
  };

  const handleCrudClick = (verb, params, pathOptions) => {
    actions[verb]({
      ...params,
      refresh: false,
      displayToast: 'Operação realizada com sucesso!',
      ...(pathOptions
        ? { updatePathOptions: pathOptions }
        : {
            id: data?.id
          })
    }).then(resp => {
      if (!resp?.error && afterSubmit) {
        afterSubmit({ data });
      }
    });
  };

  const handleClick = ({ modal, params, redirectTo }) => {
    if (redirectTo) history.push(redirectTo);
    else {
      setShowModal({ modal, params });
      if (modal !== 'confirm') afterSubmit({ data });
    }
  };

  const custom = {
    markAsAccepted: (
      <Button
        onClick={() =>
          setOpenPurchaseModal({
            open: true,
            idOrders: data?.splitId || data?.id,
            type: 'orderPayment',
            code: data?.code,
            isSplit: !!data?.splitId
          })
        }
        text
        id="mark-as-accept"
      >
        Marcar como aceita
      </Button>
    ),
    copyLink: (
      <CopyLinkButton
        onClose={f => f}
        eventButton="item"
        urlContext={shortPath}
        idData={data?.splitId || data?.id}
        id="copy-link"
        text
        isSplit={!!data?.splitId}
      >
        Copiar link
      </CopyLinkButton>
    ),
    exportPdf: (
      <Button onClick={() => handlePrint()} text>
        Exportar PDF
      </Button>
    ),
    sendToSupplier: (
      <Button id="send-supplier" onClick={() => setShowShareModal(true)} text>
        Enviar para fornecedor
      </Button>
    ),
    seeSupplierPayment: (
      <Button
        onClick={() =>
          setOpenFinanceDrawer({
            open: true,
            type: data?.payment?.isCharge ? 'payment' : 'expense',
            idPayment: data?.idPayment
          })
        }
        text
        id="see-supplier-payment"
      >
        Ver pagamento para fornecedor
      </Button>
    ),
    chargeAdministrationFee: (
      <Button
        onClick={() => setOpenPurchaseModal({ open: true, idOrders: [data?.id], type: 'administrationFee' })}
        text
        id="charge-administration-fee"
      >
        Cobrar taxa de administração
      </Button>
    ),
    requestRefund: (
      <Button
        onClick={() =>
          setOpenFinanceDrawer({
            open: true,
            type: 'payment',
            idOrders: [data?.id],
            orderType: paymentOrderType.refund
          })
        }
        text
        id="request-refund"
      >
        Solicitar reembolso
      </Button>
    )
  };

  const modal = {
    confirm: () => (
      <ConfirmModal
        onSubmit={() =>
          handleDelete({
            deletePathOptions: `/${data?.id}`,
            displayToast: 'Operação realizada com sucesso!',
            noLoading: true,
            refresh: false
          }).then(resp => {
            if (!resp?.error && afterSubmit) {
              afterSubmit({ data, isDelete: true });
            }
          })
        }
        onClose={() => setShowModal(null)}
        text={modalConfirmDelete.text}
        title={modalConfirmDelete.title}
        alertInfo={modalConfirmDelete.alertInfo}
        alertInfoPadding={spaces.space1}
        $noPadding
      />
    )
  };

  const menu = (
    <StyledMenu $alignLeftOptions>
      {allDropdown[`${model}Action`]({
        data,
        purchaseOrderStatus,
        purchaseSolicitationStatus,
        hasUserPaymentPermission,
        isMobile: isMobile()
      })?.map(option => (
        <React.Fragment key={`menu-${option.id}`}>
          <Menu.Item key={`menu-item-${option.id}`}>
            {custom[option.button] || (
              <DropDownButton
                optionData={option}
                id={option.id}
                text
                onClick={() =>
                  option.verb && !option.modal
                    ? handleCrudClick(option.verb, option.params, option.pathOptions)
                    : handleClick(option)
                }
              />
            )}
          </Menu.Item>
        </React.Fragment>
      ))}
    </StyledMenu>
  );

  return (
    <>
      <div role="presentation" onClick={e => e.stopPropagation()}>
        <OrderModals
          openPurchaseModal={openPurchaseModal}
          setOpenPurchaseModal={setOpenPurchaseModal}
          openFinanceDrawer={openFinanceDrawer}
          setOpenFinanceDrawer={setOpenFinanceDrawer}
          afterSubmit={afterSubmit}
        />
        {showModal && modal[showModal.modal] ? modal[showModal.modal](showModal.params) : null}
        <DropdownContainer mobileDirection="column">
          <Dropdown slim trigger={['click']} menu={menu} padding={0}>
            {children}
          </Dropdown>
        </DropdownContainer>
      </div>
      {showShareModal && (
        <ShareWithSupplierModal
          model="order"
          itemId={data?.splitId || data?.id}
          isSplit={!!data?.splitId}
          onClose={() => {
            setShowShareModal(false);
            if (refresh) afterSubmit({});
          }}
          onShare={() => {
            if (data?.idOrderStatus === purchaseOrderStatus?.sent) return null;

            setRefresh(true);
            return handleUpdate({
              values: { idOrderStatus: purchaseOrderStatus?.sent },
              id: data?.id,
              refresh: false,
              displayToast: 'Status da ordem de compra movido para enviado!'
            });
          }}
        />
      )}
    </>
  );
};

PurchaseActionDropdown.propTypes = {
  data: PropTypes.instanceOf(Object),
  afterSubmit: PropTypes.func,
  model: PropTypes.string,
  children: PropTypes.instanceOf(Object),
  handlePrint: PropTypes.func
};

export default PurchaseActionDropdown;
